/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  Button,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Modal,
  Grid,
  Card,
  TextField
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import ScheduleIcon from '@mui/icons-material/Schedule';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import { cancelJobRequest, rescheduleJobRequest, completeJobRequest } from '../../api/jobRequest';
import { Loader } from '../../components';

export default function Index (props) {
  const navigate = useNavigate();
  const { status, invoice, jobDetail } = props;

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [openPopup, setOpenPopup] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDateChange = e => {
    setSelectedDate(e);
  };

  const handleCompleteJobRequest = async () => {
    setLoading(true);
    if (jobDetail?.id) {
      const response = await completeJobRequest(jobDetail?.id);

      setLoading(false);
      if (response && !response?.success) {
        toast.error(response?.message);
      }

      if (response?.success) {
        toast.success(response.data?.message);
        navigate('/dashboard', { replace: true });
      }
    }
  };

  const handleRescheduleJob = async () => {
    setLoading(true);

    const id = jobDetail?.id;
    const data = {
      schedule: moment(selectedDate)?.format('YYYY-MM-DD HH:mm:ss')
    };
    const response = await rescheduleJobRequest(id, data);

    setLoading(false);
    if (response && !response?.success) {
      toast.error(response?.message);
    }

    if (response?.success) {
      toast.success(response.data?.message);
      setOpenPopup(false);
      window.location.reload();
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenReschedulePopup = () => {
    setOpenPopup(true);
  };

  const handleCancelJobRequest = async () => {
    setLoading(true);
    if (jobDetail?.id) {
      const response = await cancelJobRequest(jobDetail?.id);

      setLoading(false);
      if (response && !response?.success) {
        toast.error(response?.message);
      }

      if (response?.success) {
        toast.success(response.data?.message);
        navigate('/dashboard', { replace: true });
      }
    }
  };

  return (
    <>
    <ToastContainer />
      {loading && <Loader />}
    <div
      style={{
        display: 'flex',
        justifySelf: 'center',
        alignItems: 'center',
        marginTop: 10,
        margin: 'auto auto'
      }}
    >
      <Button variant='contained' color='primary' onClick={handleOpen}>
        Manage Request <SettingsIcon />
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> What do you want to do?</DialogTitle>
        <List sx={{ pt: 0 }}>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              disabled={status === 'accepted' || invoice?.id || status === 'closed'}
              onClick={handleOpenReschedulePopup}
            >
              <ListItemAvatar>
                <ScheduleIcon />
              </ListItemAvatar>
              <ListItemText primary='Reschedule Job Request' />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              disabled={status === 'accepted' || invoice?.id || status === 'closed'}
              onClick={() => handleCancelJobRequest()}
            >
              <ListItemAvatar>
                <CancelIcon />
              </ListItemAvatar>
              <ListItemText primary='Cancel Job Request' />
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters>
            <ListItemButton
              autoFocus
              disabled={status !== 'closed' && !invoice?.id }
              onClick={() => handleCompleteJobRequest()}
            >
              <ListItemAvatar>
              <CheckCircleIcon/>
              </ListItemAvatar>
              <ListItemText primary='Mark Job As Completed' />
            </ListItemButton>
          </ListItem>
        </List>
      </Dialog>
      <Modal
        open={openPopup}
        onClose={() => setOpenPopup(false)}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Card sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}>
          <Grid item lg={12} mt={6} mx='auto'>
            <DatePicker
              disablePast
              label='Reschedule Date'
              value={selectedDate}
              onChange={handleDateChange}
              PopperProps={{ style: { ...{ top: '25%', left: '50%' } } }}
              renderInput={params => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps, readOnly: true }}
                  variant='standard'
                  autoComplete='off'
                />
              )}
            />
          </Grid>
          <Grid item lg={12} mt={3} mx='auto'>
            <Button
              type='submit'
              size='large'
              variant='contained'
              onClick={handleRescheduleJob}
            >
              Confirm Date
            </Button>
          </Grid>
        </Card>
      </Modal>
    </div>
    </>
  );
};

Index.propTypes = {
  status: PropTypes.string.isRequired,
  invoice: PropTypes.object.isRequired,
  jobDetail: PropTypes.object.isRequired
};
