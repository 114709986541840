import * as React from 'react';
import {
  Grid,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Logo from '../components/Logo/Logo';
import { styled } from '@mui/material/styles';

export default function TermsAndCondition () {
  const [expanded, setExpanded] = React.useState(false);

  const CustomizedTypography = styled(Typography)`
  flex-shrink: 0;
  font-size: 15;
  font-weight: bold;
  width: 60%;
`;

  const handleChange = panel => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Logo />
      <Grid
        maxWidth={{ lg: '800px', sm: '500px' }}
        sx={{ margin: '50px auto 50px auto', backgroundColor: 'white' }}
        p={2}
        container
        borderRadius={3}
        // display={'flex'}
        // alignItems='center'
        // justifyContent={'center'}
        // textAlign={'center'}
        // flexDirection='column'
      >
        <Typography
          variant='h3'
          component='h3'
          m={2}
          color='primary'
          maxWidth='95%'
        >
          {'Terms And Conditions of Use'}
        </Typography>

        <Accordion
          expanded={expanded === 'panel1'}
          onChange={handleChange('panel1')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel1bh-content'
            id='panel1bh-header'
          >
            <CustomizedTypography>
              INTRODUCTION
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}>
            <Typography>
              Welcome to the “FUNDIS LLC” platform incorporated in Kenya as
              FUNDIS APP LTD, hereinafter referred to as “the Platform”. <br />
              These are the platform’s terms of use as contained on the domain{' '}
              <a
                href='http://fundis.co.ke/terms'
                target='_blank'
                rel='noreferrer'
                style={{ color: 'primary' }}
              >
                {' '}
                here
              </a>
            </Typography>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left' }}>
                <b> FUNDIS APP</b> is a platform (referral communications
                  link) that enables the connection between entities
                  (users) seeking for installation/
                  repairs/maintenance
                  services and related/associated services (YOU) and
                  entities providing such
                  services and/or products (Technicians/
                  Experts/ Pros/
                  Fixers/Fundis/
                  Artisans/Craftsmen/
                  Handymen/specialists/
                  merchants/Fundis)collectively hereinafter
                  referred to as
                  (<b>FUNDIS FIXERS</b>).
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b>FUNDIS APP LIMITED</b>, a company duly incorporated under
                  the Laws of Kenya and currently headquartered at the Reata
                  Suites, Ralph Bunche Road, Nairobi, Kenya , undertakes
                  personal and professional background checks on all FUNDIS
                  FIXERS before contracting them and continuously collects and
                  evaluates feedback from their interactions with YOU.
                  Therefore, past reliability, quality and/or suitability of
                  their service is not guaranteed with certainty per instance.
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b>FUNDIS APP LIMITED</b> holds and contracts each{' '}
                  <b>FUNDIS FIXER</b> to the highest possible industry standards
                  of service and/or products’ quality. Consequently, each{' '}
                  <b>FUNDIS FIXER</b> willingly agrees to offer YOU a job
                  warranty of not more than three (3) months on every job
                  request executed as a proof of their competency and preeminent
                  workmanship.
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b>FUNDIS APP LIMITED</b> does not directly provide the
                  repair/specialists’ service but takes responsibility towards
                  USERS (YOU) in receiving job requests on the platform,
                  acknowledging receipt of such job requests, linkage with the
                  FUNDIS FIXERS, Clients’ update on procession of jobs,
                  payments’ facilitation, complaints, returns, chargebacks et
                  cetera. In case of disputes, <b>FUNDIS APP LIMITED</b> will
                  mediate between the service/product provider and YOU to reach
                  an agreeable solution.
                </li>
                <Typography sx={{ padding: 0 }}>
                  <Typography color='primary'>
                    GENERAL RULES RELATING TO USE:
                  </Typography>
                  YOU agree that when using the platform YOU will comply with
                  all Applicable Laws and these Terms. In particular, but
                  without limitation, YOU agree that:
                </Typography>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  By using the platform (including and not limited to
                  downloading, installing, accessing and/or using the platform’s
                  <b> FUNDIS APP </b>and/or any other software availed by{' '}
                  <b>FUNDIS APP LIMITED</b> to enable the usage of the service
                  provided thereto, or by receiving ANY services from FUNDIS APP
                  LIMITED including as published from time to time on the{' '}
                  <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}
                  and any telephonic and/or social media messages sent that
                  combined constitute the platform’s service, YOU expressly
                  acknowledge and agree to be bound by the following terms and
                  conditions (“Terms“) including any subsequent amendments
                  and/or additions thereto that shall be published on{' '}
                  <b>FUNDIS APP LIMITED</b> website
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  By using the platform (including and not limited to
                  downloading, installing, accessing and/or using the platform’s
                  FUNDIS APP and/or any other software availed by{' '}
                  <b>FUNDIS APP LIMITED</b> to enable the usage of the service
                  provided thereto, or by receiving ANY services from{' '}
                  <b>FUNDIS APP LIMITED</b> including as published from time to
                  time on the website
                  <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}
                  and any telephonic and/or social media messages sent that
                  combined constitute the platform’s service, YOU expressly
                  acknowledge and agree to be bound by the following terms and
                  conditions (“Terms“) including any subsequent amendments
                  and/or additions thereto that shall be published on{' '}
                  <b>FUNDIS APP LIMITED</b>{' '}
                  <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b>FUNDIS APP LIMITED</b> reserves the right to periodically
                  amend these terms as occasion demands and such modifications
                  shall be published immediately they are made on its
                  <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}
                  and publicized on its official social media pages.
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b>FUNDIS APP LIMITED</b> guarantees YOU that such
                  modifications shall be made for the purpose of continuous and
                  never ending service improvement and/or in regards to any
                  existing Laws and/or Legal obligations existing per time.
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  YOU are however responsible for staying updated with such
                  modifications every time YOU use the platform’s service as
                  contained on{' '}
                  <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}
                  and YOUR continued use of the platform shall be construed to
                  mean you consent to such changes.
                </li>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  If YOU have any queries about these Terms and/or any part
                  contained herein that forms part of these Terms, please
                  contact <b>FUNDIS APP LIMITED</b> by any of the means set out in the
                  FUNDIS APP and/or any of its related software, or via official
                  <b>FUNDIS APP LIMITED</b>’s social media pages, or the platform’s
                  telephone numbers and CONTACT US details together with any
                  physical address provided on the <a
                    href='http://fundis.co.ke'
                    target='_blank'
                    rel='noreferrer'
                  >
                    {' '}
                    website
                  </a>{' '}.<br/>
                  <b> If YOU do not agree with these Terms, YOU should stop using
                  the Platform immediately.</b>
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel2'}
          onChange={handleChange('panel2')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel2bh-content'
            id='panel2bh-header'
          >
            <CustomizedTypography>
              FUNDIS APP PLATFORM
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 8 }}>
                  <b> FUNDIS APP LIMITED </b> and the platform’s service is not
                  a installations/ repairs/maintenance service, a specialist or
                  service provider. The company does not provide any repair
                  services or products whatsoever. It is up to the third party
                  service providers, FUNDIS’ FIXERS to offer any such repair or
                  specialist services. You will always be notified via any of
                  our channels the FUNDIS FIXERS that your job request has been
                  assigned to. <b> FUNDIS APP LIMITED </b> is not responsible or
                  liable for any specialist services or products provided to YOU
                  by any such independent contractors.{' '}
                  <b> FUNDIS APP LIMITED </b> and the platform are a passive
                  medium (a referral communications) link through which business
                  relationships can be established between private individuals
                  and the independent contractors.
                </li>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  <ol type='a'>
                    <br />
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Every job request and the Particulars of every job request
                      made by YOU shall be confirmed by an acknowledgement of
                      job request receipt from the platform each with a unique
                      identifier number e.g F-67896.
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Any job without an acknowledgement of job request receipt
                      from the platform shall not be considered as a job made on
                      the platform. You are therefore required to make sure you
                      receive a job request receipt for every job request YOU
                      make on the PLATFORM.
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Every job request made by YOU shall be relayed to the
                      relevant <b>FUNDIS FIXER</b> and YOU shall be notified of
                      every such independent contractor it has been relayed to.
                      The acknowledgement of job request receipt shall be made
                      to YOU by any one of the platform’s channels of
                      communication. Upon receipt and acceptance of the
                      acknowledgement of job request receipt from the platform,
                      YOU agree with the <b> FUNDIS APP </b>terms stated herein
                      and any other terms.
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      All monetary payment for all services rendered by FUNDIS
                      FIXERS or for the procurement of requisite materials for
                      the purposes of job requests execution shall be approved
                      by <b> FUNDIS APP LIMITED </b> and only payable to
                      accounts provided by the company with payment receipts
                      acknowledgement.
                    </li>
                  </ol>
                </li>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  When interacting with <b>FUNDIS FIXERS</b>, YOU should
                  exercise caution and common sense to protect your personal
                  safety and property, just as you would when interacting with
                  other service providers you aren’t well acquainted with.
                </li>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  <b> FUNDIS APP LIMITED </b> and its affiliates or licensors
                  will make efforts to regulate but is not responsible for the
                  conduct, whether online or offline, of any user of the
                  platform. Further, by using the platform you agree that YOU
                  shall not;
                  <ol type='a'>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Use the platform in any unlawful manner or in a manner
                      which promotes or encourages illegal activity including
                      (without limitation) copyright infringement; or
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Attempt to gain unauthorized access to the platform’s
                      website, <b> FUNDIS APP </b> mobile application or any
                      related software or any networks, servers or computer
                      systems connected thereto; or
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Modify, adapt, translate or reverse engineer any part of
                      the platform’s <b>FUNDIS APP</b> mobile application or
                      associated software except to the extent expressly
                      permitted by these Terms or by law; or
                    </li>
                    <li style={{ textAlign: 'left', padding: 10 }}>
                      Copy or distribute any parts of or the entire content of
                      the platform without written consent from the Company.
                    </li>
                  </ol>
                </li>
                <Typography style={{ textAlign: 'left', padding: 10 }}>
                  {' '}
                  YOU agree that you will access the platform only through the
                  authorized access points and that YOU will keep secure and
                  confidential your access details to the platform.
                </Typography>
                <Typography style={{ textAlign: 'left', padding: 10 }}>
                  {' '}
                  YOU agree to indemnify <b>FUNDIS APP LIMITED</b> in full and
                  on demand from and against any loss, damage, costs or expenses
                  which they suffer or incur directly or indirectly as a result
                  of YOUR use of the platform otherwise than in accordance with
                  these Terms or Applicable Laws.
                </Typography>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel3'}
          onChange={handleChange('panel3')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel3bh-content'
            id='panel3bh-header'
          >
            <CustomizedTypography >
              CONTENT
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  The copyright in all material contained on, in, or available
                  through the including all information, data, text, music,
                  sound, photographs, graphics and video messages, the selection
                  and arrangement thereof, and all source code, software
                  compilations and other material (“Material“) is owned by or
                  licensed to <b>FUNDIS APP LIMITED</b>. All rights are
                  reserved. You can view, print or download extracts of the
                  Material for your own personal use but you cannot otherwise
                  copy, edit, vary, reproduce, publish, display, distribute,
                  store, transmit, commercially exploit, disseminate in any form
                  whatsoever or use the Material without <b>FUNDIS APP</b>
                  express written permission.
                </li>
                <li style={{ textAlign: 'left', padding: 10 }}>
                  The trademarks, service marks, and logos (“Trade Marks“)
                  contained on the platform are owned by{' '}
                  <b> FUNDIS APP LIMITED </b> or its group companies or third
                  party partners of Fundis App Ltd. You cannot use, copy, edit,
                  vary, reproduce, publish, display, distribute, store,
                  transmit, commercially exploit or disseminate the Trade Marks
                  without the prior written consent of{' '}
                  <b> FUNDIS APP LIMITED </b>
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel4'}
          onChange={handleChange('panel4')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel4bh-content'
            id='panel4bh-header'
          >
            <CustomizedTypography>
              PRIVACY POLICY
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  We take your privacy very seriously. Should you feel that any
                  content in relation to the PLATFORM violates or in any way
                  infringes your privacy or any copyright, YOU may notify the
                  company through any of the availed contact channels requesting
                  such content removed or be made inaccessible. Any data
                  collected and/or stored from YOU shall only be used in
                  accordance with the terms of the FUNDIS privacy policy. By
                  using the PLATFORM, you acknowledge and agree that you have
                  read and accept the terms of our privacy policy and these
                  Terms.
                  <br />
                  {/* <a href='' target={'_blank'} rel='noreferrer'>
                    To see the full privacy policy
                  </a> */}
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel5'}
          onChange={handleChange('panel5')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel5bh-content'
            id='panel5bh-header'
          >
            <CustomizedTypography >
              DISCLAIMER / LIABILITY
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  Use of the PLATFORM is at YOUR own risk. The PLATFORM is
                  provided on an “as is” basis. In no instance shall
                  <b>FUNDIS APP LIMITED</b> and/or its licensors be liable to
                  anyone for any indirect, punitive, special, exemplary,
                  incidental, consequential or other damages of any type or kind
                  (including personal injury which may be incurred by you,
                  including but not limited to loss, damage or injury arising
                  out of, or in any way connected with the PLATFORM. To the
                  maximum extent permitted by law:
                  <ol type='a'>
                    <li style={{ textAlign: 'left', padding: 5 }}>
                      <b> FUNDIS APP LIMITED </b>disclaims all liability
                      whatsoever, whether arising in contract, tort (including
                      negligence) or otherwise in relation to the PLATFORM; and
                    </li>
                    <li style={{ textAlign: 'left', padding: 5 }}>
                      The PLATFORM acts as a communications link which you may
                      choose to secure services/products from a third party
                      (independent) contractor(s). YOU expressly waive and
                      release <b> FUNDIS APP LIMITED </b> from any and all any
                      liability, claims or damages arising from or in any way
                      related to such third party/ independent contractor (s).
                      The company will act as a party to disputes, negotiations
                      of disputes between you and contractors only to the best
                      of its ability with no expectations on resolution.
                      Responsibility for the decisions you make regarding having
                      accessed the PLATFORM rests solely with you.
                    </li>
                    <li style={{ textAlign: 'left', padding: 5 }}>
                      The quality of the services/ products is entirely the
                      responsibility of the FUNDIS.YOU understand, therefore,
                      that by using the PLATFORM, you may be exposed to services
                      that are potentially dangerous,offensive, harmful to
                      minors,unsafe or otherwise objectionable,and that you use
                      such service at your own risk
                    </li>
                    <li style={{ textAlign: 'left', padding: 5 }}>
                      All implied warranties, terms and conditions relating to
                      the PLATFORM (whether implied by statue, common law or
                      otherwise), including (without limitation) any warranty,
                      term or condition as to accuracy, completeness,
                      satisfactory quality, performance, fitness for purpose or
                      any special purpose, availability, non-infringement,
                      information accuracy, interoperability, quiet enjoyment
                      and title are, as between FUNDIS and YOU, hereby excluded.
                      in particular, but without prejudice to the foregoing,{' '}
                      <b> FUNDIS APP LIMITED </b> accepts no responsibility for
                      any technical failure of the internet and/or any software
                      made available by <b> FUNDIS APP LIMITED </b> for YOUR
                      use; or any damage or injury to users or their equipment
                      as a result of or relating to their use of any software
                      made available by
                      <b> FUNDIS APP LIMITED</b>.
                    </li>
                  </ol>
                </li>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  <b>FUNDIS APP LIMITED</b> will not be liable, in contract,
                  tort (including, without limitation, negligence), under
                  statute or otherwise, as a result of or in connection with any
                  software made available for your use by{' '}
                  <b>FUNDIS APP LIMITED</b>, for any: (i) economic loss
                  (including, without limitation, loss of revenues, profits,
                  contracts, business or anticipated savings); or (ii) loss of
                  goodwill or reputation; or (iii) special or indirect or
                  consequential loss. Nothing in these Terms shall be construed
                  as excluding or limiting the liability of{' '}
                  <b>FUNDIS APP LIMITED</b> or its group companies for death or
                  personal injury caused by its negligence or for any other
                  liability which cannot be excluded by the law.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel7'}
          onChange={handleChange('panel7')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel7bh-content'
            id='panel7bh-header'
          >
            <CustomizedTypography>
              REFUND POLICY
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  The customer refund policy applies in addition to terms and
                  conditions of use (“Terms). The customer refund policy is
                  available to customers who book a job request on the platform
                  and pay for the same through the platform’s accepted accounts
                  and suffer a job loss issue.
                  <br />
                  {/* <a href='' target={'_blank'} rel='noreferrer'>
                    See the full customer refund policy
                  </a> */}
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel8'}
          onChange={handleChange('panel8')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel8bh-content'
            id='panel8bh-header'
          >
            <CustomizedTypography>
              SERVICE SUSPENSION
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  <b>FUNDIS APP LIMITED</b> reserves the right to suspend or
                  cease providing any services relating to any software it
                  avails for your use or published by it, with or without
                  notice, and shall have no liability or responsibility to you
                  in any manner
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel9'}
          onChange={handleChange('panel9')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel9bh-content'
            id='panel9bh-header'
          >
            <CustomizedTypography>
              CANCELLATION POLICY
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  The cancellation policy applies in addition to . The
                  cancellation policy is available to customers who have booked
                  a job request on the platform and suffered a job issue.
                  <br />
                  {/* <a href='' rel='noreferrer' target='_blank'>
                    See the cancellation policy here{' '}
                  </a> */}
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel10'}
          onChange={handleChange('panel10')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel10bh-content'
            id='panel10bh-header'
          >
            <CustomizedTypography >
              ADVERTISERS IN THE PLATFORM
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  <b>FUNDIS APP LIMITED</b> accepts no responsibility whatsoever
                  for adverts contained within the <b>FUNDIS APP PLATFORM</b>.
                  If YOU agree to purchase goods and/or services from any third
                  party who advertises in the platform, YOU do so at your own
                  risk. The advertiser, not <b>FUNDIS APP LIMITED</b>, is
                  responsible for such goods and/or services and if you have any
                  queries or complaints in relation to them, your only recourse
                  is against the advertisers.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel11'}
          onChange={handleChange('panel11')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel11bh-content'
            id='panel11bh-header'
          >
            <CustomizedTypography>NOTICE</CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  The company may give notice to YOU by means of a general
                  notice on the service, electronic mail to your email address
                  on record in the company’s account information, or by SMS to
                  the number collected upon YOUR use of the service. Such notice
                  shall be deemed to have been given upon the expiration of 12
                  hours after sending.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel12'}
          onChange={handleChange('panel12')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel12bh-content'
            id='panel11bh-header'
          >
            <CustomizedTypography>
              CONTROLLING LAW AND JURISDICTION
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  These Terms will be interpreted in accordance with the laws of
                  Kenya. YOU and FUNDIS APP LIMITED agree to submit to the
                  personal jurisdiction of a for any actions for which the
                  parties retain the right to seek injunctive or other equitable
                  relief in a court of competent jurisdiction to prevent the
                  actual or threatened infringement, misappropriation or
                  violation of a party&apos;s copyrights, trademarks, trade
                  secrets, patents, or other intellectual property rights, as
                  set forth in the Dispute Resolution provision below.
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel13'}
          onChange={handleChange('panel13')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel13bh-content'
            id='panel13bh-header'
          >
            <CustomizedTypography>
              DISPUTE RESOLUTION
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography>
              <ol>
                <li style={{ textAlign: 'left', padding: 0 }}>
                  <ol type='a'>
                    <li>
                      Informal negotiations : To expedite resolution and reduce
                      the cost of any dispute, controversy or claim related to
                      this Agreement <q> Dispute</q>, YOU and the Company agree
                      to first attempt to negotiate any Dispute (except those
                      Disputes expressly excluded below) informally for at least
                      thirty (30) days before initiating any arbitration or
                      court proceeding. Such informal negotiations will commence
                      upon written notice, as set forth abov
                    </li>
                    <li>
                      If any claim or dispute cannot be settled by negotiation
                      within [21] days after either party has made a written
                      offer to the other party to negotiate a settlement to such
                      claim or dispute, the parties shall, before resorting to
                      court proceedings, attempt to resolve the claim or dispute
                      by mediation in accordance with the Strathmore Dispute
                      Resolution Centre Mediation Guidelines which Guidelines
                      are deemed to be incorporated by reference to this clause.
                    </li>
                    <li>
                      If the parties have not settled any claim or dispute by
                      mediation within 42 days from the initiation of the
                      mediation, the dispute shall be referred to an arbitrator
                      and the decision of the arbitrator shall be final
                    </li>
                  </ol>
                </li>
              </ol>
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion
          expanded={expanded === 'panel14'}
          onChange={handleChange('panel14')}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls='panel14bh-content'
            id='panel14bh-header'
          >
            <CustomizedTypography>
              GENERAL
            </CustomizedTypography>
          </AccordionSummary>
          <AccordionDetails sx={{ justifyContent: 'left' }}></AccordionDetails>
          <AccordionDetails>
            <Typography style={{ textAlign: 'left', padding: 10 }}>
              These Terms (as amended from time to time) constitute the entire
              agreement between you and concerning your use of the PLATFORM.
              <b>
                <br />
                FUNDIS APP LIMITED
              </b>{' '}
              reserves the right to update these Terms from time to time. If it
              does so, the updates will be effective immediately. You are
              responsible for regularly reviewing these Terms so that you are
              aware of any changes to them and you will be bound by the new
              policy upon your continued use of the PLATFORM. No other variation
              to these Terms shall be effective unless in writing and signed by
              an authorized representative on behalf of{' '}
              <b>FUNDIS APP LIMITED</b>.
            </Typography>
            <Typography style={{ textAlign: 'left', padding: 10 }}>
              If any provision(s) of these Terms is held by a court of competent
              jurisdiction to be invalid or unenforceable, then such
              provision(s) shall be construed, as nearly as possible, to reflect
              the intentions of the parties (as reflected in the provision(s))
              and all other provisions shall remain in full force and effect.
            </Typography>
            <Typography style={{ textAlign: 'left', padding: 10 }}>
              <b>FUNDIS APP LIMITED </b>failure to exercise or enforce any right
              or provision of these Terms shall not constitute a waiver of such
              right or provision unless acknowledged and agreed to by{' '}
              <b>FUNDIS APP LIMITED </b>
            </Typography>
          </AccordionDetails>
        </Accordion>
      </Grid>
    </>
  );
}
