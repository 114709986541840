import { useState, useEffect } from 'react';
import {
  Typography,
  Grid,
  Paper,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthCard from '../components/AuthCard/AuthCard';

const index = () => {
  const navigate = useNavigate();
  const [pageError, setPageError] = useState('');

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  return (
    <AuthCard>
      <Paper
        sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center', padding: 6 }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          component="h4"
          color="primary"
          mb={5}
        >
          Email Verified Successfully
        </Typography>
        <Typography
          variant="h6"
          component="span"
          fontWeight="normal"
          color="text.secondary"
        >
          You have successfully verified your email.
          Please click the button below to continue to the homepage
        </Typography>
        <Grid p={5}>
            <Button
              size="large"
              fullWidth
              type="submit"
              variant="contained"
              onClick={() => navigate('/')}
            >
              Go to Home
            </Button>
          </Grid>
      </Paper>
    </AuthCard>
  );
};

export default index;
