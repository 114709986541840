/* eslint-disable react/prop-types */
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Box,
  Button,
  Alert,
  Modal,
  Card,
  FormControl,
  RadioGroup,
  FormGroup,
  Checkbox,
  FormControlLabel,
  Radio,
  TextField
} from '@mui/material';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useEffect, useState } from 'react';
import { Loader } from '../../components';

import { acceptQuotation, createBreakdown, rejectQuotation } from '../../api';

function Quotation ({ quotations, jobDetail }) {
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [pageMessage, setPageMessage] = useState('');
  const [open, setOpen] = useState(false);
  const [radioValue, setRadioValue] = useState();
  const [formValues, setFormValues] = useState([
    { amount: '', description: '' }
  ]);

  const formatPrice = (amount) => {
    return `Sh ${parseInt(amount).toFixed(2)}`;
  };

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleSetPayment = (id) => {
    setOpen(true);
  };

  const handleAcceptQuotation = async (id) => {
    setLoading(true);
    const response = await acceptQuotation(id);
    setLoading(false);

    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      setPageError('You have to be authenticated to continue');
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.status === 'accepted') {
      localStorage.setItem('quotation_id', JSON.stringify(id));
    }
    window.location.reload();
  };

  const createRepaymentBreakdown = async (id, total) => {
    setLoading(true);
    let response;

    const data = {
      payment_type: radioValue
    };

    if (jobDetail.service_type === 'business' && radioValue === 'installment') {
      data.payment_breakdown = formValues || [];

      response = await createBreakdown(id, data);
    } else {
      response = await createBreakdown(id, data);
    }
    setLoading(false);
    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }

    if (!response?.success || response?.message) {
      setPageError(response?.message);
    }

    if (
      response?.success ||
      response?.message.includes('Payment breakdown already exists.')
    ) {
      setPageMessage(response?.message);
      handleAcceptQuotation(id);
    }
  };

  const handleRejectQuotation = async (id) => {
    setLoading(true);
    const response = await rejectQuotation(id);
    setLoading(false);

    if (response?.error) {
      const { error } = response;
      setPageError(error?.message);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      setPageError('You have to be authenticated to continue');
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success) {
      toast.success('Quotation successfully rejected');
    }
    window.location.reload();
  };

  const handleChange = (event) => {
    setRadioValue(event.target.value);
  };

  const handleFormChange = (i, e) => {
    const newFormValues = [...formValues];
    if (e.target.name === 'amount') {
      newFormValues[i][e.target.name] = parseFloat(e.target.value || 0);
    } else if (e.target.name === 'description') {
      newFormValues[i][e.target.name] = e.target.value;
    }
    newFormValues[i].is_service_charge = e.target.checked;

    setFormValues(newFormValues);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        amount: '',
        description: ''
      }
    ]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  return (
    <>
    <ToastContainer />
      {loading && <Loader />}
      {pageError && <Alert severity="error">{pageError}</Alert>}
      {pageMessage && <Alert severity="success">{pageMessage}</Alert>}
      {quotations.map((quotation) => (
        <>
          <Grid
            sx={{
              maxWidth: { sm: '90%', lg: '85%' },
              padding: { lg: '2rem' },
              marginX: 'auto',
              display: 'flex',
              flexFlow: 'column wrap',
              alignItems: 'center'
            }}
          >
            <Box sx={{ width: '100%' }}>
              <Typography variant="h4" component="h1">
                Job Quotation
              </Typography>
              <Typography>Ref: {quotation.reference}</Typography>
              <Typography>
                {moment(quotation.updated_at).format('MMM DD, YYYY')}
              </Typography>
              <Typography color="primary" fontWeight="bold">
                {formatPrice(quotation.total_amount)}
              </Typography>
            </Box>
            <TableContainer sx={{ marginTop: '3rem', maxWidth: 'lg' }}>
              <Table aria-label="quotation table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Item</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Quantity</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>Price</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {quotation.items.map((item) => (
                    <TableRow
                      key={item.id}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {item.item}
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell>{formatPrice(item.price)}</TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell rowSpan={2} />
                  </TableRow>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {formatPrice(quotation.total_amount)}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {quotation.status === 'pending' && (
              <Grid
                marginY={4}
                maxWidth="lg"
                marginX="auto"
                sx={{ display: 'flex', justifyContent: 'center' }}
                container
              >
                <Grid xs={12} md={4} mx={2} mt={2} item>
                  <Button
                    variant="outlined"
                    size="large"
                    fullWidth
                    onClick={() => handleRejectQuotation(quotation.id)}
                  >
                    Reject
                  </Button>
                </Grid>
                <Grid xs={12} md={4} mx={2} mt={2} item>
                  <Button
                    variant="contained"
                    size="large"
                    fullWidth
                    onClick={() => handleSetPayment(quotation.id)}
                  >
                    Accept
                  </Button>
                </Grid>
              </Grid>
            )}
            <Typography>
              N.B: A fixed charge of{' '}
              <Typography component="span" color="primary">
                SH500
              </Typography>{' '}
              will be paid as{' '}
              <Typography component="span" color="primary">
                assessment fee
              </Typography>{' '}
              if quote is rejected after physical assessment
            </Typography>
          </Grid>
          <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Card
              sx={{ ...style, width: { lg: 600, md: 300 }, padding: 'auto' }}
            >
              {pageError && <Alert severity="error">{pageError}</Alert>}
              <Grid mb={1}>
                <Typography
                  id="modal-modal-title"
                  variant="h4"
                  fontWeight="bold"
                  component="span"
                  color="primary"
                >
                  Choose Payment Method
                </Typography>
              </Grid>
              <Grid mb={4}>
                <Typography
                  id="modal-modal-title"
                  variant="subtitle1"
                  component="span"
                >
                  How would you like to pay ?
                </Typography>
              </Grid>
              <Grid mb={4}>
                <Typography
                  id="modal-modal-title"
                  variant="subtitle1"
                  component="span"
                >
                  Total: {quotation.total_amount}
                </Typography>
              </Grid>
              <Grid mb={1}>
                <FormControl>
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={radioValue}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="full"
                      control={<Radio />}
                      label="Full"
                    />
                    <FormControlLabel
                      value="installment"
                      control={<Radio />}
                      label="Installments"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
              {jobDetail.service_type === 'business' &&
                radioValue === 'installment' && (
                  <>
                    <Grid mb={1}>
                      <Typography
                        id="modal-modal-title"
                        variant="subtitle1"
                        component="span"
                      >
                        Please create an installment plan for your payment
                      </Typography>
                    </Grid>
                    <Grid p={3}>
                      {formValues.map((element, index) => (
                        <Grid container mb={2} key={index}>
                          <Grid lg={3}>
                            <TextField
                              variant="standard"
                              name="amount"
                              label="Amount"
                              value={element.amount}
                              onChange={(e) => handleFormChange(index, e)}
                              required
                            ></TextField>
                          </Grid>
                          <Grid lg={5} ml={1}>
                            <TextField
                              variant="standard"
                              name="description"
                              label="Description"
                              value={element.description}
                              onChange={(e) => handleFormChange(index, e)}
                              required
                              fullWidth
                            ></TextField>
                          </Grid>
                          <Grid lg={3} mt={2} ml={1}>
                            <FormGroup>
                              <FormControlLabel
                                control={<Checkbox />}
                                label="Service Charge"
                                onChange={(e) => handleFormChange(index, e)}
                              />
                            </FormGroup>
                          </Grid>
                          {index
                            ? (
                            <Grid
                              sx={{ float: 'left', marginTop: '10px' }}
                              item
                              pl={5}
                            >
                              <Button
                                variant="text"
                                fontWeight="bold"
                                onClick={() => removeFormFields(index)}
                              >
                                <DeleteIcon /> Remove
                              </Button>
                            </Grid>
                              )
                            : (
                                ''
                              )}
                        </Grid>
                      ))}
                      <Grid item sx={{ float: 'right' }}>
                        <Button
                          variant="text"
                          fontWeight="bold"
                          onClick={() => addFormFields()}
                        >
                          <AddCircleIcon /> Add More
                        </Button>
                      </Grid>
                    </Grid>
                  </>
              )}
              <Grid mt={5}>
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  sx={{ width: 300, margin: 'auto' }}
                  onClick={() =>
                    createRepaymentBreakdown(
                      quotation.id,
                      quotation.total_amount
                    )
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Card>
          </Modal>
        </>
      ))}
    </>
  );
}

export default Quotation;
