import { Grid, Typography } from '@mui/material';
import Logo from '../components/Logo/Logo';

function Index () {
  return (
    <>
      <Logo />
      <Grid
        maxWidth={{ lg: '800px', sm: '500px' }}
        sx={{ margin: '50px auto 50px auto', backgroundColor: 'white' }}
        p={4}
        container
        borderRadius={3}
        display={'flex'}
        alignItems='center'
        justifyContent={'center'}
        textAlign={'center'}
        flexDirection='column'
      >
       <Grid
            lg={12}
            item
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            flexDirection="column"
          >
              <Typography variant="h3" component="h3" mt={2} color="primary" maxWidth="95%">
              {'Quotation Updated'}
            </Typography>
            <Typography variant="h6" component="h6" mt={4} mb={4} maxWidth="95%">
            {
              'The status of your quotation has been successfully updated.'
            }
          </Typography>
        </Grid>
      </Grid>
    </>
  );
}

export default Index;
