import { useNavigate } from 'react-router-dom';
import {
  Grid,
  Typography,
  Button
} from '@mui/material';
import Logo from '../components/Logo/Logo';

function EmailVerificationFailure () {
  const navigate = useNavigate();
  return (
      <>
        <Logo />
        <Grid
          maxWidth={{ lg: '800px', sm: '500px' }}
          sx={{ margin: '50px auto 50px auto', backgroundColor: 'white' }}
          p={4}
          container
          borderRadius={3}
          display={'flex'}
          alignItems="center"
          justifyContent={'center'}
          textAlign={'center'}
          flexDirection="column"
        >
          <Grid
            lg={12}
            item
            display={'flex'}
            alignItems="center"
            justifyContent={'center'}
            flexDirection="column"
          >
              <Typography variant="h3" component="h3" mt={2} color="primary" maxWidth="95%">
              {'Oops.....'}
            </Typography>
            <Typography variant="h6" component="h6" mt={8} mb={4} maxWidth="95%">
              This Email Verification link has expired <br/> Click the button below to return to the homepage
            </Typography>
            <Button
              size="large"
              variant={'contained'}
              sx={{ px: 10 }}
              type="submit"
              onClick={() => navigate('/')}
            >
              Return to Homepage
            </Button>
          </Grid>
        </Grid>
      </>
  );
}

export default EmailVerificationFailure;
