/* eslint-disable react/prop-types */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Box,
  Typography,
  Alert,
  Tooltip,
  FormControlLabel,
  Checkbox
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Autocomplete from '@mui/material/Autocomplete';
import ImageIcon from '@mui/icons-material/Image';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import phone from 'phone';
import { createJobRequest } from '../../api';

function BusinessRequestForm ({ requestTypes, serviceType, locations }) {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [schedule, setSchedule] = useState(
    moment().format('YYYY-MM-DD HH:mm:ss')
  );
  const [pageError, setPageError] = useState(false);
  const [error, setError] = useState('');
  const [formValues, setFormValues] = useState([
    {
      service_type: serviceType,
      request_type_id: '',
      location_id: '',
      location_address: '',
      schedule,
      description: '',
      contact_phone: '',
      contact_name: '',
      file: [],
      marked: checked
    }
  ]);

  const handleChange = (i, e) => {
    const newFormValues = [...formValues];
    const checkBox = 'marked';
    if (e.target.type === 'checkbox') {
      newFormValues[i][checkBox] = e.target.checked;
    }
    if (e.target.name === 'schedule') {
      newFormValues[i][e.target?.name] = moment(e.target.value).format(
        'YYYY-MM-DD HH:mm:ss'
      );
    } else {
      newFormValues[i][e.target?.name] =
        e.target.type === 'file'
          ? e.target.files[0]
          : e.target.type === 'tel'
            ? phone(`+254-${e.target.value}`).phoneNumber
            : e.target?.value;
      setFormValues(newFormValues);
    }
  };

  const handleCheckbox = (i, e) => {
    setChecked(e.target.checked);
    handleChange(i, e);
  };

  const addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        service_type: serviceType,
        request_type_id: '',
        location_id: '',
        schedule,
        location_address: '',
        description: '',
        contact_phone: '',
        contact_name: '',
        file: []
      }
    ]);
  };

  const removeFormFields = (i) => {
    const newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const removeFile = (index, element) => {
    const newFormValues = [...formValues];
    newFormValues[index].file = [];
    setFormValues(newFormValues);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    callCreateJobRequest(formValues);
  };

  const callCreateJobRequest = (requests) => {
    createJobRequest(requests).then((response) => {
      setLoading(true);
      if (response?.success) {
        toast.success(`${response.data?.message}`);
        setTimeout(() => navigate('/dashboard', { replace: true }), 6000);
      } else {
        setLoading(false);
        setPageError(true);
        setError(
          response?.exception_message.replace(/job_request\.\d{1}\./g, '')
        );
      }
    });
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 3000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);
  return (
    <form onSubmit={handleSubmit}>
      <ToastContainer />
      {formValues.map((element, index) => (
        <>
          <Grid mt={5} columnSpacing={4} key={index} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                label="Contact Person Name"
                name="contact_name"
                value={element.contact_name}
                onChange={(e) => handleChange(index, e)}
                required
                fullWidth
              />
            </Grid>
            <Grid xs={12} mb={3} lg={6} item>
              <DatePicker
                label="Schedule"
                id="schedule"
                name="schedule"
                value={schedule}
                minDate={moment().toDate()}
                onChange={(newValue) => {
                  setSchedule(() => newValue);
                  handleChange(index, {
                    target: {
                      name: 'schedule',
                      value: moment(newValue).format('YYYY-MM-DD HH:mm:ss')
                    }
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    autoComplete="off"
                    fullWidth
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                name="contact_phone"
                label="Contact Person Phone Number"
                value={element.contact_phone}
                onChange={(e) => handleChange(index, e)}
                required
                type="tel"
                fullWidth
                inputProps={{ maxLength: 12 }}
              />
            </Grid>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                name="request_type_id"
                label="Job Request Type"
                value={element.request_type_id}
                onChange={(e) => handleChange(index, e)}
                select
                required
                fullWidth
              >
                {requestTypes.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={4} md={6} item>
              <TextField
                variant="standard"
                name="location_address"
                label="Enter Your Address"
                value={element.location_address}
                onChange={(e) => handleChange(index, e)}
                required
                fullWidth
              ></TextField>
            </Grid>
            <Grid xs={12} mb={4} md={6} item>
              <Autocomplete
                id="select-location"
                name="location_id"
                sx={{ width: 300 }}
                options={locations}
                autoHighlight
                getOptionLabel={(option) => option.name}
                onChange={(e, option) => {
                  const clone = [...formValues];
                  const obj = clone[index];
                  obj.location_id = String(option.id);
                  clone[index] = obj;
                  setFormValues([...clone]);
                }}
                renderOption={(props, option) => (
                  <Box component="li" {...props}>
                    {option.name}
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Enter your location"
                    required
                    fullWidth
                    inputProps={{
                      ...params.inputProps
                      // autoComplete: 'new location', // disable autocomplete and autofill
                    }}
                  />
                )}
              />
            </Grid>
          </Grid>

          <Grid columnSpacing={4} container>
            <Grid xs={12} mb={3} lg={6} item>
              <TextField
                variant="standard"
                id="website"
                label="Business Website"
                name="business_website"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid xs={12} mt={4} item>
            <TextField
              variant="outlined"
              id="description"
              label="Job Description"
              rows={4}
              name="description"
              value={element.description}
              onChange={(e) => handleChange(index, e)}
              multiline
              required
              fullWidth
            />
          </Grid>
          <Grid xs={12} mb={1} mt={4} md={6} item>
          <FormControlLabel
            label={
              <Typography>
                Mark As Urgent
              </Typography>
            }
            control={
              <Checkbox
                checked={checked}
                onChange={ (e) => handleCheckbox(index, e)}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          ></FormControlLabel>
              </Grid>
          {element.file.length !== 0
            ? (
            <Grid my={3} columnSpacing={6} px={4} container>
              <Grid xs={12} position="relative" item>
                <Tooltip title={element.file.name}>
                  <ImageIcon color="primary" sx={{ fontSize: '96px' }} />
                </Tooltip>
                <br />
                <Typography component="small">
                  {element.file.name.substring(0, 14)}
                  {element.file.name.length > 14 && '...'}
                  <DeleteIcon
                    color="primary"
                    onClick={() => removeFile(index, element)}
                  />
                </Typography>
              </Grid>
            </Grid>
              )
            : (
            <Grid xs={12} mt={2} position="relative" item>
              <TextField
                type="file"
                id="file"
                sx={{ opacity: 0, zIndex: 100, cursor: 'pointer' }}
                name="file"
                onChange={(e) => handleChange(index, e)}
                fullWidth
              />
              <Box
                position="absolute"
                top={0}
                minHeight="100%"
                sx={{ cursor: 'pointer', display: 'flex' }}
                alignItems="center"
              >
                <CloudUploadOutlinedIcon />
                <Typography marginLeft={1}>
                  Upload Image / Video of Job
                </Typography>
                <Typography variant="body2" color="primary" size="8px" ml={1}>
                  (Note: format supported include png, pdf,jpeg, jpg)
                </Typography>
              </Box>
            </Grid>
              )}

          <Grid my={3} columnSpacing={6} px={4} container>
            <Grid xs={12} mb={2} sm={6} item>
              {index >= 0 && (
                <Button
                  variant="text"
                  fontWeight="bold"
                  onClick={() => addFormFields()}
                >
                  <AddCircleIcon /> Add more job request
                </Button>
              )}
            </Grid>
            <Grid xs={12} mb={2} sm={6} item>
              {index
                ? (
                <Button
                  variant="text"
                  fontWeight="bold"
                  onClick={() => removeFormFields(index)}
                >
                  <DeleteIcon /> Remove Job Request
                </Button>
                  )
                : null}
            </Grid>
            <Grid xs={10} marginX="auto">
              {pageError && <Alert severity="error">{error}</Alert>}
            </Grid>
          </Grid>
        </>
      ))}

      <Grid my={4} maxWidth={{ sm: '100%', lg: '50%' }} mx="auto">
        <LoadingButton
          type="submit"
          size="large"
          variant={loading ? 'variant' : 'contained'}
          loading={loading}
          loadingIndicator="Submitting..."
          fullWidth
        >
          Place Request
        </LoadingButton>
      </Grid>
    </form>
  );
}

export default BusinessRequestForm;
