import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  CardContent,
  TextField,
  Typography,
  Grid,
  Paper,
  Button
  // Alert
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AuthCard from '../components/AuthCard/AuthCard';
// import { resendOTP } from '../api/auth';
// import { Loader } from '../components';

const ForgotPassword = () => {
  const navigate = useNavigate();
  // const [loading, setLoading] = useState(false);
  // const [pageError, setPageError] = useState('');
  const [showNote, setShowNote] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = (data) => {
    localStorage.setItem('otp', JSON.stringify(data));

    navigate('/reset-password', { replace: true });
  };

  // const triggerResendOTP = async () => {
  //   setLoading(true);
  //   const response = await resendOTP();
  //   setLoading(false);
  //   if (response.error) {
  //     const { error } = response;
  //     setPageError(error.message);
  //     setLoading(false);
  //     return;
  //   }
  //   if (response?.success && response.data?.message) {
  //     ;<Alert severity='success'>{response.data?.message}</Alert>;
  //   }
  // };

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNote(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <AuthCard>
      {/* {loading && <Loader/>} */}
      <Paper
        sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center', padding: 6 }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          component="h4"
          color="primary"
          mb={5}
        >
          Reset Password Verification
        </Typography>
        <Typography
          variant="h6"
          component="span"
          fontWeight="normal"
          color="text.secondary"
        >
          A 6 - Digit PIN has been sent to your phone number, enter it below to
          continue
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent sx={{ padding: 5 }}>
            <TextField
              type="number"
              label="OTP"
              variant="standard"
              {...register('otp', {
                required: {
                  value: true,
                  message: 'Please enter your 6 Digit OTP'
                },
                minLength: {
                  value: 6,
                  message: 'Number must be 6 digits'
                },
                maxLength: {
                  value: 6,
                  message: 'Number cannot exceed 6 digits'
                }
              })}
              required={!!errors.otp}
              fullWidth
            />
            <Typography variant="small" component="small" color="error">
              {errors.otp?.message}
              {errors.otp &&
                errors.otp.type === 'validate' &&
                'Enter the OTP sent to your phone'}
            </Typography>
            {showNote && (
                <Typography mt={2}>
                  Didn&apos;t get an OTP?{' '}
                  <Typography mb={3}>
                    Use the following steps to Whitelist your phone number{' '}
                    <br /> Dial <b>*456*9#</b>,
                    Select <b> Option 5 </b>(Marketing messages)
                  </Typography>
                  {/* <Button
                    component='button'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={triggerResendOTP}
                    sx={{ padding: 0 }}
                    mt={6}
                  >
                    Resend OTP
                  </Button> */}
                </Typography>
            )}
          </CardContent>
          {/* {pageError && <Alert severity='error'>{pageError}</Alert>} */}
          <Grid p={5}>
            <Button size="large" variant={'contained'} fullWidth type="submit">
              Continue
            </Button>
          </Grid>
        </form>
        <CardContent>
          <Typography font-size="8px" color="primary">
            Please note that if you have Do not Disturb <strong> ( DND ) </strong> turned on, you will not receive an <strong>OTP </strong>via text.<br></br>
            Please either turn off your DND or check your OTP on Whatsapp too
            </Typography>
          </CardContent>
      </Paper>
    </AuthCard>
  );
};

export default ForgotPassword;
