const config = {
  api: {
    baseURL: process.env.REACT_APP_BASE_API_URL
  },
  flutter_wave: {
    public_key: process.env.REACT_APP_FLUTTER_WAVE_PUBLIC_KEY
  },
  google: {
    clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID
  }
};

export default config;
