/* eslint-disable react/prop-types */
import { List, Box } from '@mui/material';
import { useAuth } from '../../context/useAuth';
import { useEffect, useRef } from 'react';

import SingleMessage from './SingleMessage';

const messageArea = {
  height: '50vh',
  overflowY: 'auto',
  overflowX: 'hidden',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between'
};

const formatDate = (date) => {
  date = new Date(date);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + minutes + ' ' + ampm;
  return (
    date.getMonth() +
    1 +
    '/' +
    date.getDate() +
    '/' +
    date.getFullYear() +
    '  ' +
    strTime
  );
};

export default function Message (props) {
  const { messages } = props;
  const { user } = useAuth();

  const sortedMessages = messages
    ?.map((obj) => {
      return { ...obj, created_at: new Date(obj.created_at) };
    })
    .sort((a, b) => a.created_at - b.created_at);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(scrollToBottom, [messages]);

  return (
    <div>
      <Box sx={{ height: '100%' }}>
        <List sx={messageArea}>
          {sortedMessages?.map((message) => (
            <SingleMessage
              text={message?.message}
              key={message?.id}
              time={formatDate(message?.created_at)}
              sender={message?.sent_by === user?.id}
            />
          ))}
          <Box
            ref={messagesEndRef}
            sx={{ width: '100%', height: '200px', bottom: 0 }}
          ></Box>
        </List>
      </Box>
    </div>
  );
}
