import { useState, useEffect } from 'react';
import { ToggleButton, ToggleButtonGroup, Grid } from '@mui/material';
import { IndividualRequestForm, BusinessRequestForm } from '../components';
import { getLocations, getRequestTypes } from '../api/jobRequest';

function JobRequest () {
  const [serviceType, setServiceType] = useState('individual');
  const [requestTypes, setRequestTypes] = useState([]);
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    getRequestTypes().then((data) => {
      setRequestTypes(data);
    });

    getLocations().then((data) => {
      setLocations(data);
    });
  }, []);

  return (
    <>
      <Grid
        maxWidth={{ lg: 'lg', xl: 'lg' }}
        marginX="auto"
        p={2}
        minHeight="100vh"
        alignItems="center"
        container
      >
        <Grid sm={8} lg={8} mt={{ lg: 4 }} marginX="auto" item>
          <ToggleButtonGroup
            color="primary"
            value={serviceType}
            onChange={(event, newServiceType) => {
              if (newServiceType !== null) {
                setServiceType(newServiceType);
              }
            }}
            exclusive
          >
            <ToggleButton value="individual">Individual</ToggleButton>
            <ToggleButton value="business">Business</ToggleButton>
          </ToggleButtonGroup>
          {serviceType === 'individual' && (
            <IndividualRequestForm
              requestTypes={requestTypes}
              serviceType={serviceType}
              locations={locations}
            />
          )}
          {serviceType === 'business' && (
            <BusinessRequestForm
              requestTypes={requestTypes}
              serviceType={serviceType}
              locations={locations}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default JobRequest;
