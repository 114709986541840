import { Grid } from '@mui/material';
import { RequestsTable } from '../components/Table';

function Index () {
  return (
    <Grid
      maxWidth={{ lg: 'lg', xl: 'lg' }}
      // marginX='auto'
      p={5}
      minHeight="100vh"
      alignItems="center"
      container
    >
      <RequestsTable />
    </Grid>
  );
}

export default Index;
