import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  CardContent,
  TextField,
  Typography,
  Grid,
  Alert,
  Paper,
  InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useNavigate } from 'react-router-dom';
import validator from 'validator';
import AuthCard from '../components/AuthCard/AuthCard';
import { forgotPassword } from '../api/auth';
import phone from 'phone';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const onSubmit = async (data) => {
    setLoading(true);

    const response = await forgotPassword({
      ...data,
      phone: phone(`+254-${data.phone}`).phoneNumber
    });
    if (response.error) {
      const { error } = response;
      setPageError(error.message);
      setLoading(false);
      return;
    }

    if (response?.success && response.data?.message) {
      localStorage.setItem(
        'phoneNumber',
        JSON.stringify({
          ...data,
          phone: phone(`+254-${data.phone}`).phoneNumber
        })
      );

      navigate('/verify-otp', { replace: true });
    }
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  return (
    <AuthCard>
      <Paper
        sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center', padding: 6 }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          component="h4"
          color="primary"
          mb={5}
        >
          Forgot Your Password?
        </Typography>
        <Typography
          variant="h6"
          component="span"
          fontWeight="normal"
          color="text.secondary"
        >
          You forgot your password ? That’s okay, it happens! <br /> Enter the
          phone number associated with your account and click on the button
          below. <br />A text will be sent to you shortly
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardContent sx={{ padding: 5 }}>
            <TextField
              type="tel"
              label="Phone Number"
              variant="standard"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+254</InputAdornment>
                )
              }}
              fullWidth
              {...register('phone', {
                required: {
                  value: true,
                  message: 'Phone number is required'
                },
                maxLength: {
                  value: 10,
                  message: 'Phone number cannot exceed 10 digits'
                },
                validate: (value) =>
                  validator.isMobilePhone(value) &&
                  phone(`+254-${value}`).isValid
              })}
              required={!!errors.username}
            />
            <Typography variant="small" component="small" color="error">
              {errors.username?.message}
              {errors.username &&
                errors.username.type === 'validate' &&
                'Enter a valid phone number'}
            </Typography>
          </CardContent>
          {pageError && <Alert severity="error">{pageError}</Alert>}
          <Grid p={5}>
            <LoadingButton
              size="large"
              variant={loading ? 'variant' : 'contained'}
              fullWidth
              type="submit"
              loading={loading}
              loadingIndicator="Loading..."
            >
              Continue
            </LoadingButton>
          </Grid>
        </form>
      </Paper>
    </AuthCard>
  );
};

export default ForgotPassword;
