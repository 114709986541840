import { useEffect, useState } from 'react';
import {
  Box,
  List,
  ListItem,
  Link as MUILink,
  ListItemButton,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  LocalActivityOutlined,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined
} from '@mui/icons-material';
import { fetchUserProfile } from '../../api';
import { Logo } from '../';

export default function Sidebar () {
  const [verified, setVerified] = useState();
  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? 'primary' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal'
  });

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  const getUserProfile = async () => {
    const response = await fetchUserProfile();

    if (response.error) {
      const { error } = response;
      return error;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.user_type === 'customer') {
      setVerified(response?.data?.phone_verified);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          position: 'fixed',
          minHeight: '100vh',
          top: 0,
          left: 0,
          minWidth: { md: '25vw', lg: '20vw', xl: '15vw' },
          width: '250px',
          background: '#FAFAFA',
          border: '1px solid  #FAFAFA'
        }}
      >
        <List sx={{ width: '100%' }}>
          <ListItem>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                cursor: 'pointer'
              }}
            >
              <Logo />
            </Box>
          </ListItem>
          <ListItem
            sx={{
              '&:hover': {
                color: 'primary'
              }
            }}
          >
            <ListItemButton>
              <ListItemIcon>
                <DashboardCustomizeOutlined />
              </ListItemIcon>
              <MUILink
                underline="none"
                component={NavLink}
                to="/"
                color="inherit"
                style={navLinkStyle}
              >
                <ListItemText primary="Dashboard" />
              </MUILink>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton disabled={!verified}>
              <ListItemIcon>
                <RequestQuoteOutlined />
              </ListItemIcon>
              <MUILink
                underline="none"
                component={NavLink}
                to="create-job-request"
                color="inherit"
                style={navLinkStyle}
              >
                <ListItemText primary="Make a Request" />
              </MUILink>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton disabled={!verified}>
              <ListItemIcon>
                <LocalActivityOutlined />
              </ListItemIcon>
              <MUILink
                underline="none"
                component={NavLink}
                to="job-requests"
                color="inherit"
                style={navLinkStyle}
              >
                <ListItemText primary="All Requests" />
              </MUILink>
            </ListItemButton>
          </ListItem>
          <ListItem>
            <ListItemButton>
              <ListItemIcon>
                <AccountCircle />
              </ListItemIcon>
              <MUILink
                underline="none"
                component={NavLink}
                to="profile"
                color="inherit"
                style={navLinkStyle}
              >
                <ListItemText primary="Profile" />
              </MUILink>
            </ListItemButton>
          </ListItem>
          <ListItem py={1}>
            <ListItemButton>
              <ListItemIcon>
                <Logout />
              </ListItemIcon>
              <MUILink
                underline="none"
                component={NavLink}
                to=""
                onClick={() => {
                  logout();
                }}
                color="inherit"
                style={navLinkStyle}
              >
                <ListItemText primary="Logout" />
              </MUILink>
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </>
  );
}
