import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;
const uninterceptedAxiosInstance = axios.create();

export async function registerCustomer (customerData) {
  const { firstName, lastName, phone, email, password } = customerData;
  const customer = {
    first_name: firstName,
    last_name: lastName,
    phone,
    email,
    password,
    user_type: 'customer',
    confirm_password: password
  };

  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/register`,
      data: customer
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function loginCustomer (customerData) {
  const { username, password } = customerData;
  const customer = {
    username,
    password,
    user_type: 'customer'
  };

  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/login`,
      data: customer
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to log you in now. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function forgotPassword (customerPhoneNumber) {
  const { phone } = customerPhoneNumber;

  const customer = {
    phone,
    user_type: 'customer'
  };

  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/forgot-password`,
      data: customer
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to reset your password now. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function resetPassword (customerPhoneNumber) {
  const { password, confirmPassword } = customerPhoneNumber;

  const otp = JSON.parse(localStorage.getItem('otp'))?.otp;
  const phoneNumber = JSON.parse(localStorage.getItem('phoneNumber'))?.phone;

  const customer = {
    password,
    confirm_password: confirmPassword,
    otp,
    user_type: 'customer'
  };

  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/reset-password/${phoneNumber}`,
      data: customer
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? data?.message
            : 'We are unable to reset your password now. Please try again or contact us support@fundis.co.ke'
      },
      errors: data.data
    };
  }
}

export async function verifyOTP (userOtp) {
  const { otp } = userOtp;
  const customer = {
    otp
  };

  try {
    const response = await axios({
      method: 'post',
      url: '/otp/verify',
      data: customer,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? data?.message
            : status === 400 && data?.message
              ? data?.message
              : 'We are unable to verify your otp now. Please try again or contact us support@fundis.co.ke'
      },
      errors: data.data
    };
  }
}

export async function resendOTP () {
  let data;

  try {
    const response = await axios.post('/otp/resend', data);
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to resend your OTP now. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function socialLogin (customerData) {
  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/login/google/callback`,
      data: customerData
    });
    return response?.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to get you registered. Please try again or contact us support@fundis.co.ke'
            : data?.message
      },
      errors: data?.data
    };
  }
}
