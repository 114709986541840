import axios from 'axios';

export async function fetchUserProfile () {
  try {
    const response = await axios({
      method: '',
      url: '/profile'
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function resendVerificationLink () {
  try {
    const response = await axios({
      method: '',
      url: '/email/resend'
    });
    return response?.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function uploadImage (file) {
  try {
    const formData = new FormData();
    formData.append('avatar', file[0]);

    const response = await axios.post(
      'https://fundis-prod.herokuapp.com/api/v1/profile/avatar',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response?.data;
  } catch (error) {
    // Handle error
    console.error('Upload error:', error);
  }
};
