import { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Paper,
  Typography,
  TextField,
  Grid,
  Modal,
  Card,
  Alert
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import AuthCard from '../components/AuthCard/AuthCard';
import { resetPassword } from '../api/auth';

const Index = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [goBack, setGoBack] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm();

  const password = useRef({});
  password.current = watch('password', '');

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const onSubmit = async (data) => {
    setLoading(true);
    const response = await resetPassword(data);

    setLoading(false);
    if (!response?.success) {
      if (response?.errors) {
        Object.keys(response?.errors).map((key, i) => {
          const error = response?.errors[key][0];
          setPageError(error);
          return <li key={key}>{error}</li>;
        });
      } else {
        setGoBack(true);
        setPageError(response?.error?.message);
      }
    }

    if (response?.success && response.data?.message) {
      setOpen(true);
    }
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  return (
    <AuthCard>
      <Paper
        sx={{ maxWidth: 600, margin: 'auto', textAlign: 'center', padding: 6 }}
      >
        <Typography
          variant="h4"
          fontWeight="bold"
          component="h4"
          color="primary"
          mb={5}
        >
          Reset Your Password
        </Typography>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid sx={{ padding: 2 }}>
            <Grid item mb={5}>
              <TextField
                type="text"
                label="Password"
                variant="standard"
                fullWidth
                {...register('password', {
                  required: {
                    value: true,
                    message: 'Password cannot be empty'
                  },
                  minLength: {
                    value: 8,
                    message: 'Password cannot be less than 8 characters'
                  }
                })}
                required={!!errors.password}
              />
              <Typography variant="small" component="small" color="error">
                {errors.password?.message}
              </Typography>
            </Grid>
            <Grid item mb={5}>
              <TextField
                type="text"
                label="Confirm Password"
                variant="standard"
                fullWidth
                {...register('confirmPassword', {
                  required: {
                    value: true,
                    message: 'Password cannot be empty'
                  },
                  validate: (value) =>
                    value === password.current || 'The passwords do not match'
                })}
                required={!!errors.confirmPassword}
              />
              <Typography variant="small" component="small" color="error">
                {errors.confirmPassword?.message}
              </Typography>
            </Grid>
            {pageError && <Alert severity="error">{pageError}</Alert>}
          </Grid>
          {goBack && (
            <Grid pt={2}>
              <Button
                sx={{ width: '80%' }}
                size="large"
                variant="outlined"
                fullWidth
                onClick={() => navigate('/verify-otp')}
              >
                Back
              </Button>
            </Grid>
          )}
          <Grid p={5}>
            <LoadingButton
              size="large"
              variant={loading ? 'outlined' : 'contained'}
              fullWidth
              type="submit"
              loading={loading}
              loadingIndicator="Loading..."
            >
              Continue
            </LoadingButton>
          </Grid>
        </form>
      </Paper>

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Card sx={{ ...style, width: 500, padding: 'auto' }}>
          <Grid mb={4}>
            <Typography
              id="modal-modal-title"
              variant="h4"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Password Reset Succesfully
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Typography
              id="modal-modal-description"
              variant="h6"
              fontWeight="normal"
            >
              You can now use your new password to log in to your account!
            </Typography>
          </Grid>
          <Grid mb={4}>
            <Button
              type="submit"
              size="large"
              variant="contained"
              sx={{ width: 300 }}
              onClick={() => {
                navigate('/', { replace: true });
              }}
            >
              Continue
            </Button>
          </Grid>
        </Card>
      </Modal>
    </AuthCard>
  );
};

export default Index;
