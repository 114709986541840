import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Paper } from '@mui/material';

export default function Index () {
  return (
    <Paper
      sx={{ bottom: 0 }}
      component='footer'
      square
      variant='outlined'
      mb={0}
    >
      <Container maxWidth='lg'>
        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            display: 'flex',
            my: 1
          }}
        >
          <Typography component='p' fontSize={14}>
            Copyright ©{new Date().getFullYear()}- FUNDIS{' '}
          </Typography>
        </Box>

        <Box
          sx={{
            flexGrow: 1,
            justifyContent: 'center',
            display: 'flex',
            mb: 2
          }}
        >
          <Typography component='p' fontSize={14}>
            SUPPORT :{' '}
            <a
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
                cursor: 'inherit'
              }}
              href='tel:254-700-300-228'
            >
              <strong>+254 700 300 228</strong>
            </a>{' '}
            |{' '}
            <a
              style={{
                color: 'inherit',
                textDecoration: 'inherit',
                cursor: 'inherit'
              }}
              href='mailTo:support@fundis.co.ke'
            >
              <strong>support@fundis.co.ke</strong>
            </a>
          </Typography>
        </Box>
      </Container>
    </Paper>
  );
}
