/* eslint-disable react/no-unescaped-entities */
import {
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Typography,
  Alert,
  Checkbox,
  FormControlLabel
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import validator from 'validator';
import phone from 'phone';
import { useAuth } from '../../context/useAuth';
import { registerCustomer } from '../../api';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignUpForm () {
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signUpError, setSignUpError] = useState('');
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  // const [user, setUser] = useState(
  //   JSON.parse(localStorage.getItem('user')) || ''
  // );
  const auth = useAuth();

  const handleCheckbox = event => {
    setChecked(event.target.checked);
  };

  const onSubmit = async data => {
    setLoading(true);

    const response = await registerCustomer({
      ...data,
      phone: phone(`+254-${data.phone}`).phoneNumber
    });

    setLoading(false);

    if (!response?.success && response?.error) {
      const err = JSON.stringify(response?.error);

      Object.keys(err).map((key, i) => {
        const error = err.split(':');
        setSignUpError(error[1].slice(1, -2));
        return <li key='i'>{error[1].slice(1, -2)}</li>;
      });
    }

    if (response.errors?.password) {
      const { password } = response.errors;
      setSignUpError(password[0]);
      return;
    }

    if (response?.success) {
      const { token, user } = response.data;
      localStorage.setItem('token', JSON.stringify(token.access_token));
      localStorage.setItem('user', JSON.stringify(user));
      toast.success(`${response.data?.message}, Account Successfully created`);
      user.phone_verified = true;
      auth.login(user);
      navigate('/dashboard', { replace: true });
    }
  };

  useEffect(() => {
    const alert = setTimeout(() => setSignUpError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [signUpError]);

  return (
    <>
    <ToastContainer />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid mt={4} columnSpacing={4} container>
          <Grid xs={12} mb={2} lg={6} item>
            <TextField
              label='First Name'
              variant='standard'
              {...register('firstName', {
                required: { value: true, message: 'First Name is required' },
                maxLength: {
                  value: 20,
                  message: 'Name cannot be longer than 20 characters'
                }
              })}
              required={!!errors.firstName}
              fullWidth
            />
            <Typography variant='small' component='small' color='error'>
              {errors.firstName?.message}
            </Typography>
          </Grid>
          <Grid xs={12} mb={2} lg={6} item>
            <TextField
              label='Last Name'
              variant='standard'
              {...register('lastName', {
                required: { value: true, message: 'Last Name is required' },
                maxLength: {
                  value: 20,
                  message: 'Name cannot be longer than 20 characters'
                }
              })}
              required={!!errors.lastName}
              fullWidth
            />
            <Typography variant='small' component='small' color='error'>
              {errors.lastName?.message}
            </Typography>
          </Grid>
        </Grid>
        <Grid columnSpacing={4} container>
          <Grid xs={12} mb={2} lg={6} item>
            <TextField
              type='tel'
              label='Phone Number'
              variant='standard'
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>+254</InputAdornment>
                )
              }}
              {...register('phone', {
                required: {
                  value: true,
                  message: 'Phone number is required'
                },
                maxLength: {
                  value: 10,
                  message: 'Phone number cannot exceed 10 digits'
                },
                validate: value =>
                  validator.isMobilePhone(value) &&
                  phone(`+254-${value}`).isValid
              })}
              required={!!errors.phone}
              fullWidth
            />
            <Typography variant='small' component='small' color='error'>
              {errors.phone?.message}
              {errors.phone &&
                errors.phone.type === 'validate' &&
                'Enter a valid phone number'}
            </Typography>
          </Grid>
          <Grid xs={12} mb={2} lg={6} item>
            <TextField
              type='email'
              label='Email'
              variant='standard'
              {...register('email', {
                required: {
                  value: false
                },
                validate: value => { (value && validator.isEmail(value)); }
              })}
              fullWidth
            />
            <Typography variant='small' component='small' color='error'>
              {errors.email?.message}
              {errors.email &&
                errors.email.type === 'validate' &&
                'Enter a valid email'}
            </Typography>
          </Grid>
        </Grid>
        <Grid mb={4} item>
          <TextField
            type={showPassword ? 'text' : 'password'}
            label='Password'
            variant='standard'
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password visibility'
                    onClick={() => setShowPassword(!showPassword)}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              )
            }}
            {...register('password', {
              required: { value: true, message: 'Password cannot be empty' },
              minLength: {
                value: 8,
                message: 'Password cannot be less than 8 characters'
              },
              validate: value =>
                validator.isStrongPassword(value, {
                  minLength: 8,
                  minLowercase: 1,
                  minUppercase: 1,
                  minNumbers: 1,
                  minSymbols: 0
                })
            })}
            fullWidth
            required={!!errors.password}
          />
          <Typography variant='small' component='small' color='error'>
            {errors.password?.message}
            {errors.password &&
              errors.password.type === 'validate' &&
              'Password must contain a lowercase (a-z), an uppercase (A-Z) and a number (0-9)'}
          </Typography>
          {signUpError && <Alert severity='error'>{signUpError}</Alert>}
        </Grid>
        <Grid mb={3} mt={5} width='100%'>
          <FormControlLabel
            label={
              <Typography>
                I agree with the{'  '}
                <Link
                  to='/terms-and-conditions'
                  target='_blank'
                  style={{ textDecoration: 'none' }}
                >
                  <Typography color='primary' component={'span'}>
                    Terms and Conditions
                  </Typography>
                </Link>
              </Typography>
            }
            control={
              <Checkbox
                checked={checked}
                onChange={handleCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            }
          ></FormControlLabel>
        </Grid>
        <Grid mb={3} mt={5} width='100%'>
          <LoadingButton
            size='large'
            variant={loading ? 'variant' : 'contained'}
            fullWidth
            disabled={!checked}
            type='submit'
            loading={loading}
            loadingIndicator='Loading...'
          >
            Let's Go!
          </LoadingButton>
        </Grid>
      </form>
    </>
  );
}

export default SignUpForm;
