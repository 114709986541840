import {
  Box,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Link as MUILink,
  useMediaQuery
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useState } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AccountCircle,
  Logout,
  LocalActivityOutlined,
  DashboardCustomizeOutlined,
  RequestQuoteOutlined
} from '@mui/icons-material';

import { SideBar } from '../';
import LogoImage from '../../assets/images/fundis-logo.png';

function MenuBar () {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const largeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));
  // const extraLargeScreen = useMediaQuery((theme) => theme.breakpoints.up('md'));

  const navLinkStyle = ({ isActive }) => ({
    color: isActive ? '#FF5318' : 'inherit',
    fontWeight: isActive ? 'bold' : 'normal'
  });

  const logout = () => {
    window.localStorage.clear();
    window.location.reload('/');
  };

  return (
    <>
      {!largeScreen && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            bgcolor: 'background.default'
          }}
          paddingLeft={1}
          paddingY={1}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}
          >
            <Box component="img" src={LogoImage} alt="Fundis Logo" />
            <Typography variant="h6" component="span" fontWeight="bold">
              FUNDIS
            </Typography>
            {/* <Logo /> */}
          </Box>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleClick}
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            sx={{
              top: '0.75rem'
            }}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
              sx: {
                minWidth: { xs: '100vw', sm: '300px' },
                boxShadow: 'none',
                borderRadius: '0px'
              }
            }}
          >
            <MenuItem>
              <MUILink
                underline="none"
                component={NavLink}
                to="/"
                color="inherit"
                style={navLinkStyle}
              >
                <IconButton>
                  <DashboardCustomizeOutlined />
                </IconButton>
                Dashboard
              </MUILink>
            </MenuItem>
            <MenuItem>
              <MUILink
                underline="none"
                component={NavLink}
                to="create-job-request"
                color="inherit"
                style={navLinkStyle}
              >
                <IconButton>
                  <RequestQuoteOutlined />
                </IconButton>
                Make a Request
              </MUILink>
            </MenuItem>
            <MenuItem>
              <MUILink
                underline="none"
                component={NavLink}
                to="job-requests"
                color="inherit"
                style={navLinkStyle}
              >
                <IconButton>
                  <LocalActivityOutlined />
                </IconButton>
                All Requests
              </MUILink>
            </MenuItem>
            <MenuItem>
              <MUILink
                underline="none"
                component={NavLink}
                to="profile"
                color="inherit"
                style={navLinkStyle}
              >
                <IconButton>
                  <AccountCircle />
                </IconButton>
                Profile
              </MUILink>
            </MenuItem>
            <MUILink
              underline="none"
              component={NavLink}
              to=""
              onClick={() => {
                logout();
              }}
              color="inherit"
              style={navLinkStyle}
            >
              <MenuItem>
                <IconButton>
                  <Logout />
                </IconButton>
                Logout
              </MenuItem>
            </MUILink>
          </Menu>
        </Box>
      )}
      {largeScreen && <SideBar />}
    </>
  );
}

export default MenuBar;
