import axios from 'axios';
import join from 'url-join';
import config from '../config';

export const AxiosInterceptor = () => {
  const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

  axios.interceptors.request.use(
    function (configuration) {
      // start loader
      document.body.classList.add('loading-indicator');
      // Concatenate base path if not an absolute URL
      !isAbsoluteURLRegex.test(configuration.url) &&
        (configuration.url = join(config.api.baseURL, configuration.url));

      // get and set tokens
      const token = localStorage.getItem('token');
      token && (configuration.headers.Authorization = `Bearer ${JSON.parse(token)}`);

      // set headers
      configuration.headers['Content-Type'] = 'application/json';
      configuration.headers.Accept = 'application/json';

      return configuration;
    },
    function (error) {
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    function (response) {
      // stop loader
      document.body.classList.remove('loading-indicator');
      return response;
    },
    function (error) {
      if (error.response.status === 401) {
        // redirect to login page if session has expired
        document.location.pathname = '';
      }
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    }
  );
};
