/* eslint-disable react/prop-types */
import { Typography, Grid } from '@mui/material';
import Logo from '../Logo/Logo';

const Index = (props) => {
  return (
    <>
      <Logo />
      <Grid maxWidth="1200px" sx={{ margin: 'auto' }} p={2} container>
        <Grid sm={8} md={5} lg={6} item>
          <Typography variant="h4" component="h1" mt={8} mb={4} maxWidth="95%">
          Digitally{' '}
            <Typography
              variant="h4"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Find
            </Typography>{' '}
            and{' '}
            <Typography
              variant="h4"
              fontWeight="bold"
              component="span"
              color="primary"
            >
             Hire
            </Typography>{' '}
            Exceptional Artisans and Builders
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={10}
          md={7}
          lg={6}
          marginTop={2}
          item
          sx={{ backgroundColor: 'white' }}
        >
          {props.children}
        </Grid>
      </Grid>
    </>
  );
};

export default Index;
