import { useState, useEffect } from 'react';
import {
  Alert,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  Link as MUILink,
  Typography
} from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { useForm } from 'react-hook-form';
import validator from 'validator';
import { Link, useNavigate } from 'react-router-dom';
import { loginCustomer, resendOTP } from '../../api';
import { useAuth } from '../../context/useAuth';
import phone from 'phone';

function SignInForm () {
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();
  const navigate = useNavigate();
  const [signInError, setSignInError] = useState('');

  const triggerResendOTP = async () => {
    setLoading(true);
    const response = await resendOTP();
    if (response.error) {
      const { error } = response;
      setSignInError(error.message);
      setLoading(false);
      return;
    }
    if (response?.success && response.data?.message) {
      <Alert severity="success">{response.data?.message}</Alert>;
    }
  };

  const onSubmit = async (data) => {
    setLoading(true);

    const response = await loginCustomer({
      ...data,
      username: phone(`+254-${data.username}`).phoneNumber
    });
    if (response.error) {
      const { error } = response;
      setSignInError(error.message);
      setLoading(false);
      return;
    }

    if (response.errors?.password) {
      const { password } = response.errors;
      setSignInError(password[0]);
      return;
    }

    const { user, token } = response.data;
    setLoading(false);
    localStorage.setItem('token', JSON.stringify(token.access_token));
    localStorage.setItem('user', JSON.stringify(user));

    if (!user.phone_verified) {
      triggerResendOTP();
      toast.error('Phone number not verified. Please verify phone number to login');
      setTimeout(() => navigate('/verification', { replace: true }), 3000);
    } else {
      auth.login(user);
      navigate('/dashboard', { replace: true });
    };
  };

  useEffect(() => {
    const alert = setTimeout(() => setSignInError(false), 8000);
    return () => {
      clearTimeout(alert);
    };
  }, [signInError]);

  return (
    <>
    <ToastContainer />
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid mt={4} mb={2} sx={{ backgroundColor: 'white' }}>
        <TextField
          type="tel"
          label="Phone Number"
          variant="standard"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">+254</InputAdornment>
            )
          }}
          fullWidth
          {...register('username', {
            required: {
              value: true,
              message: 'Phone number is required'
            },
            maxLength: {
              value: 10,
              message: 'Phone number cannot exceed 10 digits'
            },
            validate: (value) =>
              validator.isMobilePhone(value) && phone(`+254-${value}`).isValid
          })}
          required={!!errors.username}
        />
        <Typography variant="small" component="small" color="error">
          {errors.username?.message}
          {errors.username &&
            errors.username.type === 'validate' &&
            'Enter a valid phone number'}
        </Typography>
      </Grid>
      <Grid mb={2}>
        <TextField
          type={showPassword ? 'text' : 'password'}
          label="Password"
          variant="standard"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            )
          }}
          fullWidth
          {...register('password', {
            required: { value: true, message: 'Password cannot be empty' }
          })}
          required={!!errors.password}
        />
        <Typography variant="small" component="small" color="error">
          {errors.password?.message}
        </Typography>
      </Grid>
      {signInError && <Alert severity="error">{signInError}</Alert>}
      <Grid mb={6}>
        <MUILink
          variant="body2"
          underline="none"
          component={Link}
          to="/forgot-password"
          color="inherit"
          py={1}
        >
          Forgot Password?
        </MUILink>
      </Grid>
      <Grid mb={3}>
        <LoadingButton
          size="large"
          variant={loading ? 'variant' : 'contained'}
          fullWidth
          type="submit"
          loading={loading}
          loadingIndicator="Loading..."
        >
          Sign In
        </LoadingButton>
      </Grid>
    </form>
    </>
  );
}

export default SignInForm;
