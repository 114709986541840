import { useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Paper,
  LinearProgress,
  ToggleButton,
  ToggleButtonGroup,
  Box,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/useAuth';
import { JobRequestTable, RequestScheduleTable } from '../Table';
import { fetchUserProfile } from '../../api';

function Index () {
  const { user } = useAuth();
  const [phoneVerified, setPhoneVerified] = useState();
  const [view, setView] = useState('Request Schedule');
  const navigate = useNavigate();
  const handleChange = (event, newAuthForm) => {
    if (newAuthForm !== null) {
      setView(newAuthForm);
    }
  };

  const getUserProfile = async () => {
    const response = await fetchUserProfile();

    if (response.error) {
      const { error } = response;
      return error;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.user_type === 'customer') {
      setPhoneVerified(response?.data?.phone_verified);
    }
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <Box
      style={{
        // height: '100vh',
        width: '100%',
        alignItems: 'center',
        padding: { lg: 5, sm: 3 }
      }}
      maxWidth={{ lg: 'lg', xl: 'lg' }}
    >
      <Grid container sx={{ margin: { lg: 'auto' } }} p={5}>
        <Grid
          spacing={2}
          alignItems='center'
          justifyContent='space-between'
          container
        >
          <Grid item>
            <Typography variant='h5' component='h3'>
              Hi {user?.first_name},
            </Typography>
          </Grid>
        </Grid>

        <Grid container mt={4} columnSpacing={2}>
          <Grid xs={12} mb={2} lg={6} item>
            <Button
              disabled={!phoneVerified}
              onClick={() => navigate('/dashboard/create-job-request')}
            >
              <Paper
                sx={{
                  width: { lg: 400, md: 400 },
                  height: 220,
                  backgroundColor: '#FAFAFA',
                  border: '1px solid #EFEFEF',
                  borderRadius: 2,
                  '&:hover': {
                    boxShadow: '0px 10px 14px #FCC4B0;'
                  },
                  pointer: 'cursor'
                }}
              >
                <Typography
                  variant='h5'
                  component='h4'
                  fontWeight='bold'
                  p={5}
                  mt={3}
                >
                  Make an
                  <Typography
                    variant='h5'
                    fontWeight='bold'
                    component='span'
                    color='primary'
                  >
                    {' '}
                    <br />
                    Individual{' '}
                  </Typography>
                  Request
                </Typography>
              </Paper>
            </Button>
          </Grid>
          <Grid xs={12} mb={2} lg={6} item>
           <Button disabled={!phoneVerified} onClick={() => navigate('/dashboard/create-job-request')}>
           <Paper
              sx={{
                width: { lg: 400, md: 400 },
                height: 220,
                backgroundColor: '#FAFAFA',
                border: '1px solid #EFEFEF',
                borderRadius: 2,
                '&:hover': {
                  boxShadow: '0px 10px 14px #FCC4B0;'
                },
                cursor: 'pointer'
              }}
            >
              <Typography
                variant='h5'
                component='h4'
                fontWeight='bold'
                p={5}
                mt={3}
              >
                Make a
                <Typography
                  variant='h5'
                  fontWeight='bold'
                  component='span'
                  color='primary'
                >
                  {' '}
                  <br />
                  Business{' '}
                </Typography>
                Request
              </Typography>
            </Paper>
           </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box
        sx={{
          pl: 5,
          pb: 1,
          maxWidth: 1000
        }}
      >
        <ToggleButtonGroup
          color='primary'
          value={view}
          onChange={handleChange}
          exclusive
          p={10}
        >
          <ToggleButton sx={{ border: 'none' }} value='Request Schedule'>
            Pending Requests
          </ToggleButton>
          <ToggleButton sx={{ border: 'none' }} value='Current Request'>
            Ongoing Requests
          </ToggleButton>
        </ToggleButtonGroup>
        <Paper elevation={0} justify='center'>
          <LinearProgress variant='determinate' value={100} />
          {view === 'Current Request' && (
            <Grid item sm={12}>
              <JobRequestTable />
            </Grid>
          )}
          {view === 'Request Schedule' && (
            <Grid item sm={12}>
              <RequestScheduleTable />
            </Grid>
          )}
        </Paper>
      </Box>
    </Box>
  );
}

export default Index;
