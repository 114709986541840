import axios from 'axios';
import config from '../config';

const { baseURL } = config.api;
const uninterceptedAxiosInstance = axios.create();

export async function sendMessage (customerMessage, requestId) {
  const token = localStorage.getItem('token');
  const { message, sent_to: sentTo, chat_type: chatType } = customerMessage;
  const chat = {
    message,
    sent_to: sentTo,
    chat_type: chatType
  };

  try {
    const response = await uninterceptedAxiosInstance({
      method: 'post',
      url: `${baseURL}/customer/chat/${requestId}`,
      data: chat,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'Your message could not be delivered. Please try again'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function getMessages (id) {
  const token = localStorage.getItem('token');
  try {
    const response = await uninterceptedAxiosInstance({
      method: 'get',
      url: `${baseURL}/customer/chat/${id} `,
      headers: {
        Authorization: `Bearer ${JSON.parse(token)}`
      }
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}
