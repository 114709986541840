/* eslint-disable no-unused-vars */
// eslint-disable-next-line no-unused-vars
import { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  TextField,
  Avatar,
  Tooltip
} from '@mui/material';
import { Loader } from '../components';
import { fetchUserProfile, uploadImage } from '../api/user';
import { useDropzone } from 'react-dropzone';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

function Index () {
  const [user, setUser] = useState([]);

  const [profileError, setProfileError] = useState('');
  const [loading, setLoading] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null);

  const getUserProfile = async () => {
    setLoading(true);

    const response = await fetchUserProfile();

    if (response.error) {
      const { error } = response;
      setProfileError(error.message);
      setLoading(false);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.user_type === 'customer') {
      setUser(response.data);
      setLoading(false);
    }
  };

  const handleImageUpload = async acceptedFiles => {
    setLoading(true);
    const uploadedImageURL = URL.createObjectURL(acceptedFiles[0]);
    setUploadedImage(uploadedImageURL);
    const response = await uploadImage(acceptedFiles);

    if (response.error) {
      const { error } = response;
      toast.error(error.message);
      setLoading(false);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }
    if (response?.success) {
      toast.success('Profile photo upload successful');
    }
    setLoading(false);
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    maxFiles: 1,
    onDrop: handleImageUpload
  });

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='flex-start'
        height='100vh'
        padding={{ xs: 2, lg: 7 }}
      >
        <Typography
          variant='h4'
          component='h3'
          marginBottom={4}
          marginLeft={-10}
        >
          My Profile
        </Typography>
        <Grid container>
          <Grid item lg={3}>
            {' '}
              <Box
                sx={{ position: 'relative', width: '150px', height: '150px' }}
              >
                <Avatar
                  {...getRootProps()}
                  sx={{
                    width: '150px',
                    height: '150px',
                    cursor: 'pointer',
                    margin: 'auto',
                    '&:hover': {
                      opacity: 0.8
                    }
                  }}
                >
                  <input {...getInputProps()} />
                  {uploadedImage
                    ? (
                    <img
                      src={uploadedImage}
                      alt='Uploaded'
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover'
                      }}
                    />
                      )
                    : (
                        'Click to upload'
                      )}
                </Avatar>
                <Tooltip title='Click Image to Upload Image'>
                  <PhotoCameraIcon
                    sx={{
                      position: 'absolute',
                      bottom: 0,
                      right: 0,
                      transform: 'translate(50%, 50%)',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      padding: '4px',
                      cursor: 'pointer'
                    }}
                  />
                </Tooltip>
              </Box>
          </Grid>
          <Grid item lg={7}>
            {' '}
            <Grid item xs={12} md={6} m={3}>
              <TextField
                variant='standard'
                label='First Name'
                required
                fullWidth
                focused
                inputProps={{ readOnly: true }}
                value={user?.first_name}
              />
            </Grid>
            <Grid item xs={12} md={6} m={3}>
              <TextField
                variant='standard'
                label='Last Name'
                required
                fullWidth
                focused
                value={user?.last_name}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} m={3}>
              <TextField
                variant='standard'
                label='Phone Number'
                required
                fullWidth
                focused
                value={user?.phone}
                inputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item xs={12} md={6} m={3}>
              <TextField
                variant='standard'
                label='Email'
                required
                fullWidth
                focused
                value={user?.email}
                inputProps={{ readOnly: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default Index;
