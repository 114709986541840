import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LoadingButton } from '@mui/lab';
import {
  Paper,
  CardContent,
  TextField,
  Typography,
  Grid,
  Alert,
  Button
} from '@mui/material';
import AuthCard from '../components/AuthCard/AuthCard';
import { useAuth } from '../context/useAuth';
import { resendOTP, verifyOTP } from '../api/auth';

const Index = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [pageError, setPageError] = useState('');
  const [showNote, setShowNote] = useState(false);
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem('user')) || ''
  );
  const auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const triggerResendOTP = async () => {
    setLoading(true);
    const response = await resendOTP();
    setLoading(false);
    if (response.error) {
      const { error } = response;
      setPageError(error.message);
      setLoading(false);
      return;
    }
    if (response?.success && response.data?.message) {
      ;<Alert severity='success'>{response.data?.message}</Alert>;
    }
  };

  const onSubmit = async data => {
    setLoading(true);
    const response = await verifyOTP(data);

    if (!response?.success) {
      const {
        error: { message }
      } = response;
      if (message === 'Phone number is already verified.') {
        toast.error('Phone number has been verified already, please sign in');
        setTimeout(() => navigate('/', { replace: true }), 6000);
      } else {
        toast.error(message);
      }
      setLoading(false);
      return;
    }

    setLoading(false);

    if (response?.success) {
      toast.success(`${response.data?.message}, Account Successfully created`);
      user.phone_verified = true;
      auth.login(user);
      setUser(user);
      localStorage.setItem('user', JSON.stringify(user));
      navigate('/dashboard', { replace: true });
    }
  };

  useEffect(() => {
    const alert = setTimeout(() => setPageError(false), 6000);
    return () => {
      clearTimeout(alert);
    };
  }, [pageError]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowNote(true);
    }, 60000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <ToastContainer />
      <AuthCard>
        <Paper
          sx={{
            maxWidth: 600,
            margin: 'auto',
            textAlign: 'center',
            padding: 6
          }}
        >
          <Typography
            variant='h4'
            fontWeight='bold'
            component='span'
            color='primary'
          >
            Verify Phone Number
          </Typography>
          <CardContent sx={{ padding: 5 }}>
            <Typography variant='h6' component='span' fontWeight='normal'>
              A 6 - Digit PIN has been sent to your Phone <br />
              number, enter it below to continue
            </Typography>
          </CardContent>
          {/* Please note that if you Do not Disturb( DND) is on, you will not receive an OTP via text.
Please either turn off your DND or check your OTP on Whatsapp too */}
          <form onSubmit={handleSubmit(onSubmit)}>
            <CardContent sx={{ padding: 5 }}>
              <TextField
                type='tel'
                label='OTP'
                variant='standard'
                {...register('otp', {
                  required: {
                    value: true,
                    message: 'Please enter your 6 Digit OTP'
                  },
                  minLength: {
                    value: 6,
                    message: 'Number must be 6 digits'
                  },
                  maxLength: {
                    value: 6,
                    message: 'Number cannot exceed 6 digits'
                  }
                })}
                required={!!errors.otp}
                fullWidth
              />
              <Typography variant='small' component='small' color='error'>
                {errors.otp?.message}
                {errors.otp &&
                  errors.otp.type === 'validate' &&
                  'Enter the OTP sent to your phone'}
              </Typography>
              {showNote && (
                <Typography mt={2}>
                  Didn&apos;t get an OTP?{' '}
                  <Typography mb={3}>
                    Use the following steps to Whitelist your phone number{' '}
                    <br /> Dial <b>*456*9#</b>,
                    Select <b> Option 5 </b>(Marketing messages), Click Resend OTP
                    Button on this page
                  </Typography>
                  <Button
                    component='button'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={triggerResendOTP}
                    sx={{ padding: 0 }}
                    mt={6}
                  >
                    Resend OTP
                  </Button>
                </Typography>
              )}
            </CardContent>
            {pageError && <Alert severity='error'>{pageError}</Alert>}
            <Grid mb={4}>
              <LoadingButton
                size='large'
                variant={loading ? 'variant' : 'contained'}
                fullWidth
                type='submit'
                loading={loading}
                loadingIndicator='Loading...'
              >
                Continue
              </LoadingButton>
            </Grid>
          </form>
          <CardContent>
            <Typography font-size='8px' color='primary'>
              Please note that if you have Do not Disturb{' '}
              <strong> ( DND ) </strong> turned on, you will not receive an{' '}
              <strong>OTP </strong>via text.<br></br>
              Please either turn off your DND or check your OTP on Whatsapp too
            </Typography>
          </CardContent>
        </Paper>
      </AuthCard>
    </>
  );
};

export default Index;
