/* eslint-disable multiline-ternary */
/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import {
  Grid,
  Paper,
  Box,
  Typography,
  Tooltip,
  Button,
  Modal,
  Snackbar,
  Alert
} from '@mui/material';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { PaymentDetails, Quotation } from '..';
import {
  cancelJobRequest,
  rescheduleJobRequest,
  completeJobRequest
} from '../../api/jobRequest';
import { stringAvatar, capitalizeInitialLetter } from '../../lib/utils';
import Chat from '../Chat/index';
import Dialog from '../Dialog/index';

export default function Index (props) {
  const { jobDetail, user, invoice, quotations } = props;
  const artisan = jobDetail?.artisan;

  const navigate = useNavigate();

  const [openChat, setOpenChat] = useState(false);
  const [notification, setNotification] = useState();
  const [jobSchedule, setJobSchedule] = useState('');
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(10);
  const [file, setFile] = useState('');
  const [isForcePickerOpen, setIsOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const status = jobDetail?.status;

  const handleOpenChat = () => setOpenChat(true);
  const handleCloseChat = () => setOpenChat(false);

  useEffect(() => {
    const files = jobDetail?.files?.filter(file => {
      setFile(file.url);
    });
  }, [jobDetail]);
  return (
    <Box
      style={{
        marginTop: '2%',
        width: '100%',
        alignItems: 'center',
        padding: { lg: 5, sm: 10 }
      }}
      maxWidth={{ lg: 'lg', xl: 'lg' }}
    >
      <Grid container justifyContent='flex-end' mb={4} pr={8}>
        <Grid item>
          <Dialog status={status} invoice={invoice} jobDetail={jobDetail} />
        </Grid>
      </Grid>
      <Grid container spacing={2} justifyContent='center'>
        <Grid item xs={12} sm={4}>
          <Paper
            style={{
              width: { lg: 450, md: 400 },
              height: 250,
              padding: '20px',
              border: '1px solid #EFEFEF',
              borderRadius: 2,
              pointer: 'cursor'
            }}
          >
            <Grid container>
              {artisan ? (
                <Grid
                  container
                  alignItems='center'
                  justifyContent={'space-between'}
                >
                  <Grid item xs={6} sm={1}>
                    <Avatar
                      sx={{ width: '75px', height: '75px', bgcolor: '#FF642F' }}
                      {...stringAvatar(
                        `${artisan?.first_name} ${artisan?.last_name}`
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} sm={6} md={6}>
                    <Button
                      variant='outlined'
                      onClick={handleOpenChat}
                      mt={2}
                      fullWidth
                    >
                      Chat with {artisan?.first_name}
                    </Button>
                  </Grid>
                  <Grid item xs={6} sm={8}>
                    <Typography variant='h5'>
                      {artisan?.first_name} {artisan?.last_name}
                    </Typography>
                    <Typography color='primary' fontWeight='bold'>
                      {jobDetail?.request_type?.name}
                    </Typography>
                  </Grid>
                  <Grid mt={3} container>
                    <Grid xs={12} lg={3} item>
                      <Rating
                        pb={4}
                        name='hover-feedback'
                        value={artisan?.average_rating?.toFixed(1) || 'N/A'}
                        readOnly
                        precision={0.5}
                        emptyIcon={
                          <StarIcon
                            style={{ opacity: 0.55 }}
                            fontSize='inherit'
                          />
                        }
                      />
                    </Grid>
                  </Grid>
                  <Grid mt={2}>
                    <Button variant='outlined' fullWidth disabled>
                      {`View ${artisan?.first_name}'s profile`}
                    </Button>
                  </Grid>
                </Grid>
              ) : (
                <Grid item xs={12}>
                  <Box
                    display='flex'
                    justifyContent='center'
                    alignItems='center'
                    height='180px'
                  >
                    <Typography variant='h5' color='secondary'>
                      No artisan data yet
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={7}>
          <Paper
            style={{
              width: { lg: 450, md: 400 },
              height: 250,
              padding: '20px',
              border: '1px solid #EFEFEF',
              borderRadius: 2,
              pointer: 'cursor'
            }}
          >
            <Grid container>
              <Grid item lg={7}>
                {jobDetail?.job_key !== 'complete' && (
                  <Typography
                    variant='p'
                    color='primary'
                    fontWeight='bold'
                    component='p'
                    sx={{
                      fontSize: {
                        xs: '1rem',
                        sm: '1.2rem',
                        md: '1.4rem',
                        lg: '1.2rem'
                      }
                    }}
                  >
                    Request ID:{' '}
                    <Typography color='secondary' variant='span'>
                      {jobDetail?.job_key}
                    </Typography>
                  </Typography>
                )}
              </Grid>
              <Grid item lg={4} mb={1} mr={2} xs={12}>
                <Typography
                  variant='p'
                  sx={{
                    fontSize: {
                      xs: '1rem',
                      sm: '1.2rem',
                      md: '1.4rem',
                      lg: '1.2rem'
                    },
                    fontWeight: 'bold'
                  }}
                >
                  Status:{' '}
                  <Typography
                    variant='p'
                    color={
                      jobDetail?.status === 'accepted'
                        ? 'status.success'
                        : jobDetail?.status === 'cancel'
                          ? 'status.failed'
                          : 'primary'
                    }
                  >
                    {jobDetail?.status === 'accepted'
                      ? 'In Progress'
                      : jobDetail?.status === 'cancel'
                        ? 'Cancelled'
                        : capitalizeInitialLetter(jobDetail?.status)}
                  </Typography>
                </Typography>
              </Grid>
              <Grid container>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  display='flex'
                  flexDirection='row'
                >
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Request Date:
                    <Typography color='secondary' variant='span' pl={1}>
                      {moment(jobDetail?.schedule).format('ll')}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid
                  item
                  lg={6}
                  md={6}
                  sm={12}
                  display='flex'
                  flexDirection='row'
                >
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Job Title:
                    <Typography color='secondary' variant='span' pl={1}>
                      {jobDetail?.request_type?.name}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={6} sm={12} display='flex' flexDirection='row'>
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Job Request Type:
                    <Typography color='secondary' variant='span' pl={1}>
                      {capitalizeInitialLetter(jobDetail?.service_type)}
                    </Typography>
                  </Typography>
                </Grid>
                <Grid item lg={12} sm={12} display='flex' flexDirection='row'>
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Request Location:
                    <Typography color='secondary' variant='span' pl={1}>
                      {jobDetail?.location?.name}
                    </Typography>
                  </Typography>
                </Grid>
                {/* <Grid item lg={6} sm={12} display='flex' flexDirection='row'>
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Time Lapse:
                    <Typography color='secondary' variant='span' pl={1}>
                      {moment(jobDetail?.created_at).fromNow()}
                    </Typography>
                  </Typography>
                </Grid> */}
                <Grid
                  item
                  lg={6}
                  sm={12}
                  xs={12}
                  display='flex'
                  flexDirection='row'
                >
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    sx={{ float: 'left' }}
                  >
                    Address:
                    <Typography color='secondary' variant='span' pl={1}>
                      {jobDetail?.address}
                    </Typography>
                  </Typography>
                </Grid>
                {jobDetail?.service_type === 'business' && (
                  <>
                    <Grid
                      item
                      lg={6}
                      sm={12}
                      display='flex'
                      flexDirection='row'
                    >
                      <Typography
                        variant='p'
                        color='primary'
                        pt={2}
                        sx={{ float: 'left' }}
                      >
                        Contact Person:
                        <Typography color='secondary' variant='span' pl={1}>
                          {jobDetail?.contact_person_name}
                        </Typography>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      lg={6}
                      sm={12}
                      display='flex'
                      flexDirection='row'
                    >
                      <Typography
                        variant='p'
                        color='primary'
                        pt={2}
                        sx={{ float: 'left' }}
                      >
                        Contact Number:
                        <Typography color='secondary' variant='span' pl={1}>
                          {jobDetail?.contact_person_phone}
                        </Typography>
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item lg={12} sm={12} display='flex' flexDirection='row'>
                  <Typography
                    variant='p'
                    color='primary'
                    pt={2}
                    pb={1.5}
                    sx={{ float: 'left' }}
                  >
                    Description:
                    <Typography color='secondary' variant='span' pl={1}>
                      {jobDetail?.description}
                    </Typography>
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        justify='flex-start'
        style={{ marginTop: '20px', marginBottom: '15px' }}
      >
        <Grid item xs={12} sm={11} ml={5}>
          {invoice && Object.keys(invoice).length > 0 ? (
            <Paper
              style={{
                padding: '20px',
                border: '1px solid #EFEFEF',
                borderRadius: 2,
                pointer: 'cursor'
              }}
            >
              <PaymentDetails
                jobDetail={jobDetail}
                invoice={invoice}
                quotations={quotations}
              />{' '}
            </Paper>
          ) : quotations.length > 0 ? (
            <Paper
              style={{
                padding: '20px',
                border: '1px solid #EFEFEF',
                borderRadius: 2,
                pointer: 'cursor'
              }}
            >
              <Quotation jobDetail={jobDetail} quotations={quotations} />{' '}
            </Paper>
          ) : (
            <Paper
              style={{
                padding: '20px',
                height: '300px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #EFEFEF',
                borderRadius: 2,
                flexDirection: 'column'
              }}
            >
              <Box sx={{ width: '100%', margin: 10 }}>
                <Typography variant='h4' component='h1'>
                  Job Invoice
                </Typography>
                <Typography>Ref: Not Applicable</Typography>
                <Typography>Status: Waiting Confirmation</Typography>
                <Typography color='primary' fontWeight='bold'>
                  KES 0.00
                </Typography>
              </Box>
              <Box
                width={{ sm: '90%', lg: '70%' }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column' // Set flex direction to column
                }}
              >
                <Typography variant='h6' component='h5' alignSelf='center'>
                  No Quotation Data Yet
                </Typography>
              </Box>
            </Paper>
          )}
        </Grid>
      </Grid>
      <Modal
        open={openChat}
        onClose={handleCloseChat}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
        sx={{
          display: 'flex',
          align: 'center',
          justifyContent: 'center'
        }}
      >
        <Chat
          name={artisan?.first_name}
          artisanId={artisan?.id}
          jobRequestId={jobDetail?.id}
        />
      </Modal>
      <Grid container mt={5}>
        {notification && (
          <Snackbar
            autoHideDuration={3000}
            open={notification}
            onClose={() => setNotification('')}
            color='primary'
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Alert severity='success' color='primary' minWidth='100%'>
              {notification}
            </Alert>
          </Snackbar>
        )}
      </Grid>
    </Box>
  );
}
