import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
  Home,
  VerifyPhone,
  ForgotPassword,
  ResetPassword,
  VerifyOTP,
  JobRequest,
  JobDetails,
  AllJobRequests,
  Profile,
  PageNotFound,
  VerifyEmail,
  EmailVerificationFailure,
  TermsAndCondition,
  QuotationUpdated
} from './pages';
import { ThemeProvider } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import theme from './theme';
import Dashboard from './pages/Dashboard';
import { AuthProvider } from './context/useAuth';
import { Protected, DashboardCard } from './components';
import { AxiosInterceptor } from './_helpers/axios.interceptors';
import config from '../src/config';

AxiosInterceptor();
const root = ReactDOM.createRoot(document.getElementById('root'));
const { clientId } = config.google;

root.render(
  <React.StrictMode>
   <GoogleOAuthProvider clientId={ clientId }>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <AuthProvider>
              <Routes>
                <Route path='/' element={<Home />} />
                <Route
                  path='dashboard'
                  element={
                    <Protected redirectPath='/'>
                      <Dashboard />
                    </Protected>
                  }
                >
                  <Route
                    path='create-job-request'
                    element={
                      <Protected redirectPath='/'>
                        <JobRequest />
                      </Protected>
                    }
                  />
                  <Route
                    path='job-requests'
                    element={
                      <Protected redirectPath='/'>
                        <AllJobRequests />
                      </Protected>
                    }
                  />
                  <Route
                    path='job-requests/:id'
                    element={
                      <Protected redirectPath='/'>
                        <JobDetails />
                      </Protected>
                    }
                  />
                  <Route
                    index
                    element={
                      <Protected redirectPath='/'>
                        <DashboardCard />
                      </Protected>
                    }
                  />
                  <Route
                    path='profile'
                    element={
                      <Protected redirectPath='/'>
                        <Profile />
                      </Protected>
                    }
                  />
                </Route>
                <Route path='/verification' element={<VerifyPhone />} />
                f<Route path='/verify-otp' element={<VerifyOTP />} />
                <Route
                  path='/email-verification-success'
                  element={<VerifyEmail />}
                />
                <Route
                  path='/email-verification-failure'
                  element={<EmailVerificationFailure />}
                />
                <Route
                  path='/terms-and-conditions'
                  element={<TermsAndCondition />}
                />
                 <Route
                  path='/quotation-updated'
                  element={<QuotationUpdated />}
                />
                <Route path='/forgot-password' element={<ForgotPassword />} />
                <Route path='/reset-password' element={<ResetPassword />} />
                <Route path='*' exact element={<PageNotFound />} />
              </Routes>
            </AuthProvider>
          </BrowserRouter>
        </ThemeProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
