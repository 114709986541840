/* eslint-disable multiline-ternary */
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Button, Chip, Stack } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { fetchAllJobRequests } from '../../api/jobRequest';
import { useAuth } from '../../context/useAuth';
import Loader from '../Loader';
import { capitalizeInitialLetter } from '../../lib/utils';

const headers = [
  'Job Title',
  'Status',
  'Location',
  'Description',
  'Request Type',
  'Date Created'
];
function TablePaginationActions (props) {
  const theme = useTheme();

  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(255, 83, 24, 0.08)'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  },
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

export default function CustomPaginationActionsTable () {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [loading, setLoading] = useState(false);
  const [jobRequest, setJobRequests] = useState([]);
  const [jobDetails, setJobDetails] = useState();
  const { user } = useAuth();

  const getJobRequests = async (page) => {
    setLoading(true);
    const response = await fetchAllJobRequests(page + 1);
    setLoading(false);
    if (response.error) {
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.data) {
      const { data } = response;
      setJobRequests(data?.data);
      setJobDetails(data);
    }
  };

  useEffect(() => {
    getJobRequests(page);
  }, [page]);

  useEffect(() => {
    setJobRequests(jobRequest);
  }, [jobRequest]);

  const getJobDetails = (row) => {
    localStorage.setItem(`${row.id}`, JSON.stringify(row));
    navigate(`${row.id}`);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <>
      {loading && <Loader />}
      {jobRequest.length > 0 ? (
        <Box width="100%" minHeight="90vh">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            my={4}
          >
            <Typography>Hi {user.first_name},</Typography>
            {/* <Button variant="outlined" p="1rem">
              Export
            </Button> */}
          </Box>
          <TableContainer>
            <Table p={2} aria-label="customized table">
              <TableHead>
                <TableRow>
                  {headers.map((column) => (
                    <StyledTableCell key={1}>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: 16, fontWeight: 'bold' }}
                      >
                        {column}
                      </Typography>
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {jobRequest > 0
                  ? jobRequest.slice(
                    page * rowsPerPage,
                    page * (rowsPerPage - 1) + rowsPerPage
                  )
                  : jobRequest.map((row) => (
                      <StyledTableRow
                        key={row.id}
                        onClick={() => getJobDetails(row)}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row.request_type?.name}
                        </StyledTableCell>
                        <TableCell component="th" scope="row">
                          {(() => {
                            switch (row.status) {
                              case 'cancel':
                                return (
                                  <Chip
                                    label="Cancelled"
                                    color="error"
                                    size="small"
                                  />
                                );
                              case 'accepted':
                                return (
                                  <Chip
                                    label="Accepted"
                                    color="success"
                                    size="small"
                                  />
                                );
                              case 'pending':
                                return (
                                  <Chip
                                    label="Pending"
                                    color="warning"
                                    size="small"
                                  />
                                );
                              case 'closed':
                                return (
                                  <Chip
                                    label="Completed"
                                    color="default"
                                    size="small"
                                  />
                                );
                              default:
                                return '';
                            }
                          })()}
                        </TableCell>
                        <TableCell>{row.location?.name}</TableCell>
                        <TableCell>
                          {' '}
                          <Typography
                            style={{
                              maxWidth: 250, // percentage also works
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis'
                            }}
                          >
                            {row.description}{' '}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {capitalizeInitialLetter(row.service_type)}
                        </TableCell>
                        <TableCell>
                          {moment(row?.schedule).format('ll')}
                        </TableCell>
                      </StyledTableRow>
                  ))}
              </TableBody>

              <TableFooter>
                <TableRow
                  sx={{
                    borderBottom: '1px red solid',
                    borderWidth: 10,
                    border: 'none'
                  }}
                >
                  <TablePagination
                    rowsPerPageOptions={[15]}
                    count={jobDetails?.total}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page'
                      },
                      native: true
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Box
          display="flex"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginX: 'auto'
          }}
        >
          {' '}
          <Stack>
            <Typography variant="h4" textAlign="center" pb={6}>
              You currently do not have any requests
            </Typography>
            <Button
              variant="outlined"
              pt={6}
              sx={{ alignSelf: 'center', paddingX: '1rem' }}
              onClick={() => navigate('/dashboard/create-job-request')}
            >
              Make a request
            </Button>
          </Stack>
        </Box>
      )}
    </>
  );
}
