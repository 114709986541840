import axios from 'axios';

export async function getReference (amount, id) {
  const data = {
    amount
  };

  try {
    const response = await axios.post(`/transaction/reference/${id}`, data);

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unabale to process your request now. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}

export async function getPaymentUrl (transID) {
  try {
    const response = await axios.get(
      `/customer/transaction/payment/url/${transID}`
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to complete your transaction. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
};

export async function makeKopoKopoPayment (transID, phone) {
  const data = { phone };
  try {
    const response = await axios.post(
      `/customer/transaction/kopokopo/payment/${transID}`, data
    );

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to complete your transaction. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
};
