/* eslint-disable react/prop-types */
// import { useTheme } from '@mui/material/styles'

import { Grid, ListItem, ListItemText } from '@mui/material';

export default function SingleMessage (props) {
  const { text, time, sender } = props;
  // const theme = useTheme()

  const sendStyle = {
    my: '5px',
    position: 'relative',
    float: 'right',
    marginRight: '20px',
    padding: '10px',
    backgroundColor: '#FF5318',
    color: '#ffffff',
    width: '50%',
    textAlign: 'left',
    borderRadius: '20px 20px 0px 20px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  };

  const receiveStyle = {
    my: '5px',
    position: 'relative',
    marginLeft: '20px',
    padding: '10px',
    backgroundColor: '#f6f5f8',
    width: '50%',
    textAlign: 'left',
    color: '#000000',
    borderRadius: '20px 20px 20px 0px',
    boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px'
  };

  const timeStyle = {
    color: '#fff'
  };

  return (
    <div>
      <ListItem key="2" sx={sender ? sendStyle : receiveStyle}>
        <Grid container>
          <Grid item xs={12}>
            <ListItemText
              align={sender ? 'right' : 'left'}
              primary={text}
            ></ListItemText>
          </Grid>
          <Grid item xs={12}>
            <ListItemText
              sx={timeStyle}
              align={sender ? 'right' : 'left'}
              secondary={time}
            ></ListItemText>
          </Grid>
        </Grid>
      </ListItem>
    </div>
  );
}
