import { useEffect, useState } from 'react';
import {
  Box,
  Alert,
  Button,
  AlertTitle,
  Modal,
  Card,
  TextField,
  Grid,
  Typography
} from '@mui/material';
import { MenuBar, Footer, Loader } from '../';
import { useAuth } from '../../context/useAuth';
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from 'react-toastify';
import { fetchUserProfile, resendVerificationLink } from '../../api/user';
import { fetchAllJobRequests } from '../../api/jobRequest';
import { resendOTP, verifyOTP } from '../../api/auth';

// eslint-disable-next-line react/prop-types
function Layout ({ children }) {
  const { user } = useAuth();
  const [loading, setLoading] = useState(false);
  const [checkUser, setCheckUser] = useState();
  const [schedule, setSchedule] = useState();
  const [popup, setPopup] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm();

  const style = {
    position: 'absolute',
    top: '50%',
    padding: 10,
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '400px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: 2,
    pt: 2,
    px: 4,
    pb: 3,
    textAlign: 'center'
  };

  const handleResendLink = async () => {
    setLoading(true);

    const response = await resendVerificationLink();
    setLoading(false);
    if (response && !response?.success) {
      toast.error(response?.message);
    }

    if (response?.success) {
      toast.success(response.data?.message);
      window.location.reload();
    }
  };

  const onSubmit = async data => {
    setLoading(true);
    const response = await verifyOTP(data);

    if (!response?.success) {
      const {
        error: { message }
      } = response;
      if (message === 'Phone number is already verified.') {
        toast.error('Phone number has been verified already, please sign in');
      } else {
        toast.error(message);
      }
      setLoading(false);
      return;
    }

    setLoading(false);

    if (response?.success) {
      toast.success(`${response.data?.message}, Account Successfully created`);
      setTimeout(() => window.location.reload(), 6000);
    }
  };

  const triggerResendOTP = async () => {
    setLoading(true);
    const response = await resendOTP();
    setLoading(false);
    if (response.error) {
      const { error } = response;
      toast.error(error.message, 6000);
      setLoading(false);
      return;
    }
    if (response?.success && response.data?.message) {
      toast.success(response.data?.message, 6000);
      setPopup(true);
    }
  };

  const getUserProfile = async () => {
    setLoading(true);

    const response = await fetchUserProfile();

    if (response.error) {
      const { error } = response;
      toast.error(error);
      setLoading(false);
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.user_type === 'customer') {
      localStorage.setItem('userProfile', JSON.stringify(response?.data));
      setCheckUser(response?.data);
      setLoading(false);
    }
  };

  const getJobRequests = async () => {
    setLoading(true);
    const response = await fetchAllJobRequests();
    setLoading(false);
    if (response.error) {
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.data) {
      const {
        data: { data }
      } = response;

      if (data.some(item => item.status === 'pending')) {
        const filteredArray = data.filter(item => item.status === 'pending');
        const temp = filteredArray?.map(schedule =>
          Math.abs(new Date() - new Date(schedule.schedule).getTime())
        );
        const idx = temp?.indexOf(Math.min(...temp));
        let scheduleString = filteredArray[idx]?.schedule;
        scheduleString = scheduleString?.slice(0, scheduleString?.indexOf(' '));
        setSchedule(scheduleString);
      }
    }
  };

  useEffect(() => {
    getUserProfile();
    getJobRequests();
  }, [user]);

  return (
    <>
      {loading && <Loader />}
      <ToastContainer />
      <MenuBar />
      <Box
        sx={{
          display: 'flex',
          // justifyContent: 'center',
          flexDirection: 'column',
          bgcolor: 'background.default',
          minHeight: '100vh'
        }}
        maxWidth={{ md: '75vw', lg: '80vw', xl: '85vw' }}
        marginLeft={{ md: 'auto' }}
        component='main'
      >
        {checkUser?.email && checkUser?.email_verified_at === null && (
          <Alert marginTop={{ lg: '20px' }} severity='warning'>
            {' '}
            <AlertTitle>Warning</AlertTitle>
            Your email address <strong>{checkUser?.email}</strong> has not been
            verified <Button onClick={handleResendLink}> Verify </Button>
          </Alert>
        )}
        {checkUser?.phone && !checkUser?.phone_verified && (
          <Alert marginTop={{ lg: '20px' }} severity='warning'>
            {' '}
            <AlertTitle>Warning</AlertTitle>
            Your phone number <strong>{checkUser?.phone}</strong> has not been
            verified. Click the verify button to unlock the{' '}
            <strong> Make a Request</strong> button{' '}
            <Button onClick={triggerResendOTP}> Verify Phone Number </Button>
          </Alert>
        )}
        {schedule !== undefined && schedule >= new Date() && (
          <Alert marginTop={{ lg: '20px' }} severity='info'>
            {' '}
            <AlertTitle>Alert!!</AlertTitle>
            You have an upcoming job scheduled for <b>{schedule}</b>
          </Alert>
        )}
        {children}
      </Box>
      <Footer
        sx={{
          marginTop: 'auto'
        }}
      />
      {popup && (
        <Modal
          open={popup}
          onClose={() => setPopup(false)}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'
        >
          <Card sx={{ ...style, width: { lg: 500 }, padding: 'auto' }}>
          <Grid mb={1}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              fontWeight="bold"
              component="span"
              color="primary"
            >
              Please enter the 6-Digit OTP sent to your mobile phone
            </Typography>
          </Grid>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid xs={12} m={3} lg={6} item>
              <TextField
                type='tel'
                label='OTP'
                variant='standard'
                {...register('otp', {
                  required: {
                    value: true,
                    message: 'Please enter your 6 Digit OTP'
                  },
                  minLength: {
                    value: 6,
                    message: 'Number must be 6 digits'
                  },
                  maxLength: {
                    value: 6,
                    message: 'Number cannot exceed 6 digits'
                  }
                })}
                required={!!errors.otp}
                fullWidth
                />
                 <Typography variant='small' component='small' color='error'>
                {errors.otp?.message}
                {errors.otp &&
                  errors.otp.type === 'validate' &&
                  'Enter the OTP sent to your phone'}
              </Typography>
                <Typography mt={2}>
                  Didn&apos;t get an OTP?{' '}
                  <Typography mb={3}>
                    Use the following steps to Whitelist your phone number{' '}
                    <br /> Dial <b>*456*9#</b>,
                    Select <b> Option 5 </b>(Marketing messages), Click Resend OTP
                    Button on this page
                  </Typography>
                  <Button
                    component='button'
                    color='primary'
                    size='large'
                    fullWidth
                    onClick={triggerResendOTP}
                    sx={{ padding: 0 }}
                    mt={6}
                  >
                    Resend OTP
                  </Button>
                </Typography>
              </Grid>
              <Grid mb={3}>
                <Button
                  size='large'
                  variant={'contained'}
                  fullWidth
                  type='submit'
                  loadingIndicator='Loading...'
                >
                  Submit
                </Button>
              </Grid>
            </form>
          </Card>
        </Modal>
      )}
    </>
  );
}

export default Layout;
