import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTheme, styled } from '@mui/material/styles';
import { Box, Typography, Chip } from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import IconButton from '@mui/material/IconButton';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import { fetchAllJobRequests } from '../../api/jobRequest';
import Loader from '../Loader';

const headers = ['Job Title', 'Status', 'Location', 'Date Created'];
function TablePaginationActions (props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl'
          ? (
          <KeyboardArrowRight />
            )
          : (
          <KeyboardArrowLeft />
            )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl'
          ? (
          <KeyboardArrowLeft />
            )
          : (
          <KeyboardArrowRight />
            )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired
};
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: 'rgba(255, 83, 24, 0.08)'
  },
  '&:last-child td, &:last-child th': {
    border: 0
  },
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  }
}));

export default function CustomPaginationActionsTable () {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [requestSchedule, setRequestSchedule] = useState([]);

  const getJobRequests = async () => {
    setLoading(true);
    const response = await fetchAllJobRequests();
    setLoading(false);
    if (response.error) {
      return;
    }
    if (
      !response?.success &&
      response?.message?.toLowerCase().includes('unauthenticated.')
    ) {
      window.localStorage.clear();
      window.location.href = '/';
    }

    if (response?.success && response?.data?.data) {
      const {
        data: { data }
      } = response;

      if (data.some((item) => item.status === 'pending')) {
        const filteredArray = data.filter((item) => item.status === 'pending');
        setRequestSchedule(filteredArray);
      }
    }
  };

  useEffect(() => {
    getJobRequests();
  }, []);

  const getJobDetails = (row) => {
    localStorage.setItem(`${row.id}`, JSON.stringify(row));
    navigate(`job-requests/${row.id}`);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - jobRequest.length) : 0

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer>
      {loading && <Loader />}
      {requestSchedule.length > 0
        ? (
        <Table
          sx={{ width: { lg: 900, sm: 600 } }}
          p={2}
          aria-label="customized table"
        >
          <TableHead>
            <TableRow>
              {headers.map((column) => (
                <StyledTableCell key={1} align={'center'}>
                  <Typography
                    variant="h6"
                    sx={{ fontSize: 16, fontWeight: 'bold' }}
                  >
                    {column}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? requestSchedule.slice(
                page * rowsPerPage,
                page * rowsPerPage + rowsPerPage
              )
              : requestSchedule
            ).map((row) => (
              <StyledTableRow key={row.id} onClick={() => getJobDetails(row)}>
                <StyledTableCell align="center" component="th" scope="row">
                  {row?.request_type?.name || '-'}
                </StyledTableCell>
                <TableCell align="center" component="th" scope="row">
                  {(() => {
                    switch (row.status) {
                      case 'cancel':
                        return (
                          <Chip label="Cancelled" color="error" size="small" />
                        );
                      case 'accepted':
                        return (
                          <Chip label="Accepted" color="success" size="small" />
                        );
                      case 'pending':
                        return (
                          <Chip label="Pending" color="warning" size="small" />
                        );
                      default:
                        return '';
                    }
                  })()}
                </TableCell>
                <TableCell align="center">{row.location?.name}</TableCell>
                <TableCell align="center">
                  {moment(row.created_at).format('ll')}
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>

          <TableFooter>
            <TableRow
              sx={{
                borderBottom: '1px red solid',
                borderWidth: 10,
                border: 'none'
              }}
            >
              <TablePagination
                rowsPerPageOptions={[5, { label: 'All', value: -1 }]}
                colSpan={3}
                count={requestSchedule.length}
                rowsPerPage={rowsPerPage}
                page={page}
                SelectProps={{
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }}
                sx={{ justifySelf: 'center' }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
              />
            </TableRow>
          </TableFooter>
        </Table>
          )
        : (
        <TableBody sx={{ alignContent: 'center' }}>
          <TableRow>
            <Typography
              p={5}
              variant="h6"
              sx={{ alignText: 'center', align: 'center', margin: 'auto' }}
            >
              You currently do not have any pending job requests
            </Typography>
          </TableRow>
        </TableBody>
          )}
    </TableContainer>
  );
}
