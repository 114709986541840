import axios from 'axios';

export async function fetchQuotations (id) {
  try {
    const response = await axios({
      method: '',
      url: `/customer/quotation/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getInvoice (id) {
  try {
    const response = await axios({
      method: '',
      url: `/customer/invoice/${id}`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createBreakdown (id, data) {
  try {
    const response = await axios.post(
      `/customer/transaction/payment/breakdown/${id}`,
      data
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getBreakdown (id) {
  try {
    const response = await axios.get(
      `/customer/transaction/payment/breakdown/${id}`
    );
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function acceptQuotation (id) {
  try {
    const response = await axios({
      method: 'POST',
      url: `/customer/quotation/${id}/accepted`
    });
    return response.data.data;
  } catch (error) {
    return error.response.data;
  }
}
export async function rejectQuotation (id) {
  try {
    const response = await axios({
      method: 'POST',
      url: `/customer/quotation/${id}/rejected`
    });
    return response.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createReview (data) {
  try {
    const response = await axios.post('/reviews', data);

    return response.data;
  } catch ({ response }) {
    const { data, status } = response;
    return {
      error: {
        message:
          status === 500
            ? 'We are unable to process your request now. Please try again or contact us support@fundis.co.ke'
            : data.message
      },
      errors: data.data
    };
  }
}
